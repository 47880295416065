import React from 'react';

export default function DesignSection({ title, subtitle, style, children }) {
  return (
    <div className="medium-bottom-margin" style={style}>
      <div className="small-padding">
        <h3>{title}</h3>
        {subtitle && <p className="subheading">{subtitle}</p>}
      </div>
      <div className="small-padding" style={{paddingTop:'0px'}}>
        {children}
      </div>
    </div>
  );
}
