import React, { Component } from 'react';
import ScrollFadeIn from './../../components/scrollFadeIn';
import {Colors} from './../../utils/constants';
import DesignSection from './designSection';

export default class SigmaSection extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div className="full-width sectionContainer">
        <div className="max-width-container">
          <div className="medium-top-margin">
            <h2 className="small-padding" style={{paddingBottom:0, paddingTop:0}}>{'Sigma Design Best Practices'}</h2>
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color">{"Recommended Page Layout "}</h4>
                <p className="subheading">
                {"Refrain from including an abundance of content on a single page that intends to fulfill multiple purposes. Our clients ability to use the dashboard partly depends on their available brain capacity (cognitive load). The more content and options we put in front of them the less likely they are to complete any tasks."}
              </p>
            </div>
          </div>
          <div className="small-padding">
            <div className="shadow">
              <ScrollFadeIn>
                <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                  <div className="small-padding">
                    <img src={'/design/rules-sigma-recommended-page-layout.png'} className="full-width" />
                  </div>
                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color">{"Landing Page"}</h4>
                <p className="subheading">
                {"The landing page should engage and encourage users to interact with the dashboard, making a positive first impression that reflects the value of the dashboard. Ensure the landing page is a quick overview or answers the most important question our clients ask. Refrain from making documentation (like methodology) the landing page of the dashboard."}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-landing-page-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-landing-page-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color">{"Saved Filters"}</h4>
                <p className="subheading">
                {"If filters need to be saved on your dashboard, it is important to note that they operate at the level of the entire workbook. This implies that when a user saves filters, they are applied to all pages within that workbook. However, filters saved in one workbook do not extend to another workbook within the same dashboard."}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 1: "}</span>{'Filter Element Position'}</h4>
                <p className="subheading">
                {"Position filters and other control elements on top of the page"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-1-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-1-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 2: "}</span>{'Chart Specific Filter Element Position'}</h4>
                <p className="subheading">
                {"Filters impacting a single visual may be positioned below the title and aligned to the left"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-2-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-2-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 3: "}</span>{'Shared Filters'}</h4>
                <p className="subheading">
                {"When you have filters that remain consistent across pages (static) and others that are specific to certain pages (variable), it is recommended to place the static filters on the left and the variable filters on the right."}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 4: "}</span>{'Default Values'}</h4>
                <p className="subheading">
                {"Always set default filter values."}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-4-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-4-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 5: "}</span>{'Legend Placement'}</h4>
                <p className="subheading">
                {"Legend placement should be at the bottom of the chart"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-5-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-5-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 6: "}</span>{'Date Rotation'}</h4>
                <p className="subheading">
                {"Rotate dates for time series"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 7: "}</span>{'Date Formatting'}</h4>
                <p className="subheading">
                {"Dates on time series should be concise"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 8: "}</span>{'Number of Lines'}</h4>
                <p className="subheading">
                {"The default number of lines on a chart should not be more than 6"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 9: "}</span>{'Sidebar Navigation'}</h4>
                <p className="subheading">
                {"Use sidebar to navigate between workbook and pages instead of buttons"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-9-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-9-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 10: "}</span>{'Card Settings'}</h4>
                <p className="subheading">
                {"Disable show cards so that the background is consistent across all dashboards"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/design/rules-sigma-10-do.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/design/rules-sigma-10-dont.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 11: "}</span>{'Page Titles'}</h4>
                <p className="subheading">
                {"Page title specifications should be _____"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 12: "}</span>{'Chart Titles'}</h4>
                <p className="subheading">
                {"Individual visuals title specifications should be _____"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>
          <div className="medium-top-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h4 className="dark-grey-color"><span className="primary-color">{"Guideline 13: "}</span>{'Default Margins'}</h4>
                <p className="subheading">
                {"Margins should be set to medium"}
              </p>
            </div>
            <div className="small-padding">
              <div className="shadow">
                <ScrollFadeIn>
                  <div className="row">
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#4cd964'}}>{'Do'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #4cd964"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                    <div
                      className="small-12 medium-6 full-height small-padding">
                      <h6 style={{color:'#ff2d55'}}>{'Don\'t'}</h6>
                      <div className="tiny-padding" style={{border: "2px solid #ff2d55"}}>
                        <img src={'/images/rectangle.png'} className="full-width" />
                      </div>
                    </div>
                  </div>
                </ScrollFadeIn>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
}
