import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";

export default class AdminEmployeeNavigator extends Component {

  componentDidMount = async() =>{
    let val = await CheckVal();
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background centered-text">
          <h2 className="white">{'Admin'}</h2>
        </div>
        <div className="row">
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/categories">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Categories'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Manage and create link categories'}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/links">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Links'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Manage and create links'}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
