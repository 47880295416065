import React, { Component } from 'react';
import Parse from 'parse';
import {GetUrlVariable} from './../../utils/functions';
import ReportSectionEditableImage from './../reportSectionEditableImage';
import ParseEditableInput from './../../components/parseEditableFormElements/input';

export default class ChartSection extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  deleteSection = async() => {
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("deleted",true);
    let blogPost = await post.save();
    this.setState({
      item:blogPost
    })
  }
  updateType = async(type) => {
    this.setState({
      type:type
    })
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("type",this.state.type);
    let blogPost = await post.save();
    this.setState({
      item:blogPost
    })
  }

  componentDidMount = () =>{
    this.initScreen();
  }
  initScreen = () =>{
    let item = this.props.item;
    let image = item.attributes.image;
    this.setState({
      id:item.id,
      item:item
    })
  }
  render() {
    let item = this.state.item;
    if(!item) return null;
    let editable = this.props.editable;
    return (
      <div className="section-wrapper white-background report-chart-section">
         <div className="row">
           <div className="small-12 medium-2 tiny-padding">
            <h6>{'Chart Exhibit'}</h6>
            <ParseEditableInput
              parseclass="YipitReportSections"
              parseobjectid={this.state.item.id}
              parsekey="exhibit"
              parsevalue={this.state.item.attributes.exhibit}
              placeholder="Chart Exhibit Number" />
           </div>
           <div className="small-12 medium-10 tiny-padding">
            <h6>{'Chart Title'}</h6>
            <ParseEditableInput
              parseclass="YipitReportSections"
              parseobjectid={this.state.item.id}
              parsekey="title"
              parsevalue={this.state.item.attributes.title}
              placeholder="Chart Title" />
           </div>
         </div>
         <div className="tiny-padding">
          <ReportSectionEditableImage
            item={item}
            parseclass="YipitReportSections"
            ratio="rectangle"
            parseobjectid={item.id}
            parsekey="image"
             />
         </div>
         <div className="tiny-padding">
         <div className="small-12 medium-12 note">
          <h6>{'Note'}</h6>
            <ParseEditableInput
              parseclass="YipitReportSections"
              parseobjectid={this.state.item.id}
              parsekey="note"
              parsevalue={this.state.item.attributes.note}
              placeholder="Chart Title" />
           </div>
         </div>
      </div>
    );
  }
}
