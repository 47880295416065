import React, { Component } from 'react';
import Parse from 'parse';

export default class AdminProcess extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }

  componentDidMount = async() =>{
          /*
    const Products = Parse.Object.extend("Products");
    const products = new Parse.Query(Products);
    products.limit(10000)
    let productResults = await products.find();
    for (let i = 0; i < productResults.length; i++) {
      let prod = productResults[i];

      const CoverageCompanies = Parse.Object.extend("CoverageCompanies");
      let coverageCompany = new CoverageCompanies;
      coverageCompany.set("name",prod.attributes.name)
      coverageCompany.set("symbol",prod.attributes.symbol)
      coverageCompany.set("color",prod.attributes.color)
      coverageCompany.set("image",prod.attributes.image)
      coverageCompany.set("status",prod.attributes.status)
      coverageCompany.set("description",prod.attributes.description)
      coverageCompany.set("subheading",prod.attributes.subheading)
      let newCompany  = await coverageCompany.save();
      console.log(newCompany)
      const CoverageProducts = Parse.Object.extend("CoverageProducts");
      let coverageProduct = new CoverageProducts;
      coverageProduct.set("name",prod.attributes.name)
      coverageProduct.set("status",prod.attributes.status)
      coverageProduct.set("description",prod.attributes.description)
      coverageProduct.set("subheading",prod.attributes.subheading)
      coverageProduct.set("companies",[newCompany.id])
      coverageProduct.save()
      console.log(coverageProduct)

    }
    */
    /*
    const Bundles = Parse.Object.extend("ProductBundles");
    const bundles = new Parse.Query(Bundles);
    bundles.limit(10000)
    let bundleResults = await bundles.find();
    for (let i = 0; i < bundleResults.length; i++) {
      let bundle = bundleResults[i];
      let products = bundle.attributes.products;
      let newProductIds = [];
      if(products){
        let oldProducts = productResults.filter((product)=>products.indexOf(product.id) != -1);
        console.log(oldProducts)
        for (let x = 0; x < oldProducts.length; x++) {
          const NewCompanies = Parse.Object.extend("CoverageCompanies");
          const companies = new Parse.Query(NewCompanies);
          companies.limit(10000)
          companies.equalTo('symbol',oldProducts[x].attributes.symbol);
          let productResult = await companies.first();
          newProductIds.push(productResult.id)
        }
        console.log(newProductIds)
      }
      const CoverageProducts = Parse.Object.extend("CoverageProducts");
      let coverageProduct = new CoverageProducts;
      coverageProduct.set("name",bundle.attributes.name)
      coverageProduct.set("status",bundle.attributes.status)
      coverageProduct.set("description",bundle.attributes.description)
      coverageProduct.set("subheading",bundle.attributes.subheading)
      coverageProduct.set("companies",newProductIds)
      //coverageProduct.save()
      console.log(coverageProduct)
    }
    */
    /*
    const Bundles = Parse.Object.extend("ProductBundles");
    const bundles = new Parse.Query(Bundles);
    bundles.limit(10000)
    let bundleResults = await bundles.find();
    const Products = Parse.Object.extend("Products");
    const products = new Parse.Query(Products);
    products.limit(10000)
    let productResults = await products.find();

    const Groups = Parse.Object.extend("ProductGroups");
    const groups = new Parse.Query(Groups);
    groups.limit(10000)
    let groupResults = await groups.find();

    for (let i = 0; i < groupResults.length; i++) {
      let group = groupResults[i];
      let groupProducts = groupResults[i].attributes.products;
      let groupBundles = groupResults[i].attributes.bundles;

      let newProductIds = [];
      let oldProducts = productResults.filter((product)=>groupProducts.indexOf(product.id) != -1);
      for (let x = 0; x < oldProducts.length; x++) {
        const NewCompanies = Parse.Object.extend("CoverageProducts");
        const companies = new Parse.Query(NewCompanies);
        companies.limit(10000)
        companies.equalTo('name',oldProducts[x].attributes.name);
        let productResult = await companies.first();
        if(productResult){
          newProductIds.push(productResult.id)
        }
      }
      if(groupBundles){
        let oldBundles = bundleResults.filter((bundle)=>groupBundles.indexOf(bundle.id) != -1);
        for (let x = 0; x < oldBundles.length; x++) {
          const NewComps = Parse.Object.extend("CoverageProducts");
          const newComps = new Parse.Query(NewComps);
          newComps.limit(10000)
          newComps.equalTo('name',oldBundles[x].attributes.name);
          let bundleResult = await newComps.first();
          newProductIds.push(bundleResult.id)
        }
      }

      const CoveragePackages = Parse.Object.extend("CoveragePackages");
      let coveragePackage = new CoveragePackages;
      coveragePackage.set("name",group.attributes.name)
      coveragePackage.set("status",group.attributes.status)
      coveragePackage.set("color",group.attributes.color)
      coveragePackage.set("description",group.attributes.description)
      coveragePackage.set("order",group.attributes.order)
      coveragePackage.set("subheading",group.attributes.subheading)
      coveragePackage.set("products",newProductIds)
      console.log(coveragePackage)
      //coveragePackage.save()
    }
      */
  }

  render() {
    console.log('admin processing')
    return <span />
  }
}
