import React, { Component } from 'react';
import "./coverageSummaryHeader.css";
import {withRouter, Link, NavLink} from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import {Colors} from './../../utils/constants';
import PortalModal from './../portalModal';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class ProductDashboardHeader extends Component {
  constructor(props) {
      super(props);
      this.state = {
        searchTerm: null,
        updateKey:10001
      }
  }
  componentDidUpdate = () =>{
    if(window.location.pathname.indexOf('search') == -1 && this.state.searchTerm != null){
      console.log(this.state.searchTerm)
      this.setState({
        searchTerm:null,
        updateKey:this.state.updateKey+1
      })
    }
  }
  onPrintClick = () =>{
    if(!this.state.printModalActive){
      this.setState({
        printModalActive:true
      })
    } else{
      this.setState({
        printModalActive:false
      })
    }
  }
  onPrintClick = () =>{
    if(!this.state.printModalActive){
      this.setState({
        printModalActive:true
      })
    } else{
      const body = document.querySelector("body");
      body.style.overflow = "auto";
      this.setState({
        printModalActive:false
      })
    }
  }

  onSearch = (e) =>{
    let value = e.target.value;
    if(!value){
      this.props.history.push('/')
      setTimeout(()=>{
        document.getElementById("search-bar").focus();
      },100)
    } else{
      if(window.location.href.indexOf('/search') == -1){
        this.props.history.push('/search')
      }
    }
    if(this.props.onSearch){
      this.props.onSearch(value)
    }
    this.setState({
      searchTerm:value
    })
  }
  render() {
    let active;
    let print;
    let themeClass = "";
    let logo = "/images/logo-dark.png";
    let lightLogo = "/images/logo-light.png";
    let activeClass = "";
    if(this.props.active){
      activeClass = "active"
      active = this.props.active;
    }
    let admin = false;
    if(window.location && window.location.pathname && window.location.pathname.indexOf('admin') != -1){
      admin = true
    }
    if(window.location && window.location.pathname && window.location.pathname.indexOf('print') != -1){
      active = true;
      print = true;
    }

    return (
      <div className={"product-dashboard-header-container edison-header-padding "+activeClass+" "+themeClass}>
        <div className="product-dashboard-header-logo-container">
          <a href="https://www.yipitdata.com" target="_blank">
            {active ?
              <img alt="Edison Software white logo" className="header-logo" src={logo} />
            :
              <img alt="Edison Software dark logo" className="header-logo" src={lightLogo} />
            }
          </a>
        </div>
        {print &&
          <div style={{float:'right',fontWeight:'bold', cursor:'pointer'}}>
            <Link to={'/'}>
              {(window.location.href.indexOf('Large') != -1 )? <p style={{color:'#0d4753', marginTop:'10px'}}>{'Detailed Coverage Summary'}</p> : <p style={{color:'#0d4753', marginTop:'10px'}}>{'Coverage Summary'}</p>}
            </Link>
          </div>
        }
        {!admin && !print &&
          <div className="menu" style={{display:'flex'}}>
            <div className="search-bar">
              <div className="search-bar-background" style={{backgroundColor:'rgba(0,0,0,.2)', borderRadius:20}}>
                <div className="row" style={{padding:10, paddingLeft:15}}>
                  <MaterialIcon
                    className="material-icons"
                    icon={'search'}
                    size="small"
                    color={'#fff'} />
                  <div style={{flex:1, display:'flex', alignItems:'center', marginLeft:10}}>
                    <input
                     id={'search-bar'}
                      key={this.state.updateKey}
                      type="text"
                      value={this.state.searchTerm}
                      onChange={this.onSearch}
                      className="dark-transparent-input"
                      style={{backgroundColor:'rgba(0,0,0,0)', color:'#fff', fontSize:18}}
                      placeholder="search..." />
                  </div>
                </div>
              </div>
            </div>
            <div className="coverage-summary-print-icon" onClick={this.onPrintClick} style={{cursor:'pointer'}}>
              <MaterialIcon
                className="material-icons"
                icon={'print'}
                size="small"
                color={'#fff'} />
            </div>
          </div>
        }
        {this.state.printModalActive &&
          <PortalModal
            modalHidden={this.onPrintModalHidden}
            modalActive={this.state.printModalActive}
            content={
              <div className="white-background" onClick={this.onPrintClick}>
                <div className="tiny-padding">
                  <Link to="/print"><div className="button primary-background white">{'Compact Print View'}</div></Link>
                </div>
                <div className="tiny-padding">
                  <Link to="/printLarge"><div className="button primary-background white">{'Detailed Print View'}</div></Link>
                </div>
              </div>
            }
          />
        }
      </div>
    );
  }
}

export default withRouter(ProductDashboardHeader);
