import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class ParseEditableArray extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        value:"",
        array:this.props.array
      })
  }

  addItem = (val) =>{
    var array = this.state.array;
    array.push(val)
    this.setState({
      array:array,
      value:""
    })
  }
  removeItem = (val) =>{
    console.log(val)
    var array = this.state.array;
    var newArray = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i] != val){
        newArray.push(array[i])
      }
      if(i == array.length -1){
        this.setState({
          array:newArray,
          value:''
        },()=>{
          this.updateData()
        })
      }
    }
  }

  onChange = (e) =>{
    var value = e.target.value
    console.log(e.target.value)
    this.setState({
      value:value
    })
  }
  onKeyPress = (e) =>{
    if(e.key === 'Enter'){
      var array = this.state.array;
      array.push(this.state.value);
      this.setState({
        array:array,
        value:''
      },()=>{
        this.updateData()
      })
    }
  }
  updateData = () =>{
    this.props.callback(this.state.array);
  }

  render() {
    return (
      <div>
        <input className="light-grey-background full-width" placeholder={'enter a value'} value={this.state.value} type="text" onKeyPress={(e) =>{this.onKeyPress(e)}} onChange={(e) =>{this.onChange(e)}} style={{padding:"10px"}} />
        {this.state.array &&
          this.state.array.map((data) =>{
            return(
              <div className="pill">
                <div className="row">
                  {data}
                  <span className="white-background circle">
                    <img src="/images/close-white.png" style={{height:"20px",width:"20px", marginLeft:"20px", cursor:'pointer'}} onClick={() => {this.removeItem(data)}} />
                  </span>
                </div>
              </div>
            )
          })}
      </div>
    );
  }
}
