import React from 'react';
import { AddToClipBoard } from './../../utils/functions';

export default function ColorSquare({ color }) {
  console.log(color)
  let name, clr, hex, pantone, cmyk;
  clr  =  color
  if (color.name){
    name  = color.name
  }
  if (color.hex){
    clr = color.hex;
  }
  if (color.pantone){
    pantone = color.pantone
  }
  if (color.cmyk){
    cmyk = color.cmyk
  }
  // this is just a color
  if(clr){
    clr = clr.toLowerCase();
  }

  return (
    <div className="tiny-padding" style={{ maxWidth: '300px', paddingLeft:'0px'}}>
      <div className="white-background shadow" onClick={() => AddToClipBoard(clr)}>
        <img className="full-width" src="/images/square.png" style={{ backgroundColor: clr }} />
        <div className="tiny-padding" style={{ minWidth: 110 }}>
          {name && (
            <p>
              <b>{name}</b>
            </p>
          )}
          {clr &&
            <p className="subheading" style={{paddingTop: '8px'}}>
              <b>{'HEX'}</b><br/> {clr}
            </p>
          }
          {pantone && (
            <p className="subheading" style={{paddingTop: '8px'}}>
              <b>{'PANTONE'}</b><br/> {pantone}
            </p>
          )}
          {cmyk && (
            <p className="subheading" style={{paddingTop: '8px'}}>
              <b>{'CMYK'}</b><br/> {cmyk}
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
