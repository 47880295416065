import React, { Component } from 'react';
import Parse from 'parse';
import {GetUrlVariable} from './../../utils/functions';

export default class ReportSection extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  deleteSection = async() => {
    var sections = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(sections);
    let section = await query.get(this.props.item.id)
    section.set("deleted",true);
    let blogPost = await section.save();
    this.props.onChange();
    this.setState({
      item:blogPost
    })
  }
  updateType = async(type) => {
    var sections = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(sections);
    let result = await query.get(this.state.id)
    result.set("type",type);
    let savedResult = await result.save();
    this.props.onChange();
    this.setState({
      item:savedResult,
      type:type
    })
  }

  componentDidMount = () =>{
    this.initScreen();
  }
  initScreen = () =>{
    let item = this.props.item;
    let content = item.attributes.content;
    let image = item.attributes.image;
    this.setState({
      id:item.id,
      item:item,
      content:content
    })
  }
  render() {
    let item = this.state.item;
    if(!item) return null;
    return (
      <div className={"section-wrapper"}>
         <div className="row tiny-padding">
          <div className="small-12 medium-2 tiny-padding centered-text white-background">
            <div className="button primary-background white" onClick={()=>this.updateType('title')}>
              {'Add Title Section'}
            </div>
          </div>
          <div className="small-12 medium-2 tiny-padding centered-text white-background">
            <div className="button primary-background white" onClick={()=>this.updateType('image')}>
              {'Add Image Section'}
            </div>
          </div>
          <div className="small-12 medium-2 tiny-padding centered-text white-background">
            <div className="button primary-background white" onClick={()=>this.updateType('text')}>
              {'Add Text Section'}
            </div>
          </div>
          <div className="small-12 medium-2 tiny-padding centered-text white-background">
            <div className="button primary-background white" onClick={()=>this.updateType('chart')}>
              {'Add Chart Section'}
            </div>
          </div>
          <div className="small-12 medium-2 tiny-padding centered-text white-background">
            <div className={"editable-header"}>
             <img className="editable-delete" onClick={this.deleteSection} src="/images/close-black.png" />
            </div>
          </div>
         </div>
      </div>
    );
  }
}
