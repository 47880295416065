import React, { Component } from 'react';
import "./linkDashboardHeader.css";
import {withRouter, Link,NavLink} from 'react-router-dom';
import MaterialIcon from 'material-icons-react';
import {Colors} from './../../utils/constants';
import AnchorLink from 'react-anchor-link-smooth-scroll'

class LinkDashboardHeader extends Component {
  constructor(props) {
      super(props);
  }

  render() {
    let active;
    let themeClass = "";
    let logo = "https://assets.easilydo.com/v2/img/brand/edison/logo-dark.png";
    let lightLogo = "https://assets.easilydo.com/v2/img/brand/edison/logo-light.png";
    let activeClass = "";
    if(this.props.active){
      activeClass = "active"
      active = this.props.active;
    }


    return (
      <div className={"design-header-container edison-header-padding "+activeClass+" "+themeClass}>
        <div className="design-header-logo-container">
          <Link name="Home" to="/">
            <img alt="Edison Software white logo" className="header-logo light" src={lightLogo} />
            <img alt="Edison Software dark logo" className="header-logo dark" src={logo} />
          </Link>
        </div>
        <div
          className="show-for-small menu-icon-mobile tiny-left-margin"
          onClick={this.toggleMobileMenu}>
           <MaterialIcon className="material-icons dark" icon="menu" color={Colors.primaryTextColor} />
           <MaterialIcon className="material-icons light" icon="menu" color={Colors.white} />
        </div>
        <div className="menu hide-for-small">
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#logo'}>{'Logo'}</AnchorLink>
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#colors'}>{'Colors'}</AnchorLink>
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#powerpoints'}>{'Presentations'}</AnchorLink>
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#letterheads'}>{'Letterhead'}</AnchorLink>
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#visualizations'}>{'Visualizations'}</AnchorLink>
          <AnchorLink onClick={this.props.toggleMobileMenu} className="menu-item" href={'#bios'}>{'Bios'}</AnchorLink>
        </div>
      </div>
    );
  }
}

export default withRouter(LinkDashboardHeader);
