import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Parse from 'parse';
import { withController, Parallax } from 'react-scroll-parallax';
import {GetUrlVariable} from './../../utils/functions';
import './blog.css';
import PortalModal from './../../components/portalModal';

class BlogHome extends Component {
  constructor(props) {
      super(props);
      this.state ={};
  }
  componentDidMount = async() =>{
    let id = GetUrlVariable('id');
    let reports = Parse.Object.extend("YipitReports");
    let query = new Parse.Query(reports);
    let post = await query.get(id);
    var Sections = Parse.Object.extend("YipitReportSections");
    var sectionQuery = new Parse.Query(Sections);
    sectionQuery.equalTo('parent',id);
    sectionQuery.ascending('order');
    sectionQuery.notEqualTo('deleted',true);
    let sections = await sectionQuery.find();
    console.log(post)
    console.log(sections)
    this.setState({
      post:post,
      sections:sections
    })
  }

  showImage =(url) =>{
    this.setState({
      isOpen:true,
      fullScreenImage:url
    })
  }

  hideImage =() =>{
    this.setState({
      isOpen:false,
      fullScreenImage:null,
      onFullScreenImageLoaded:false
    })
  }

  onFullScreenImageLoad = () => {
    this.setState({
      onFullScreenImageLoaded:true
    })
  }

  render() {
    if(!this.state.post) return null;
    let fullScreenImageLoaded = "";
    if(this.state.onFullScreenImageLoaded){
      fullScreenImageLoaded ="loaded"
    }
    return (
      <div>
        <img className="blog-back" src="/images/back-white.png" onClick={this.goBack} />
        <div className="centered-text large-padding background-image-fill" style={{backgroundImage:'url(https://reports.yipitdata.com/YIPITDATA/data/YDL-Background-cedb9c28.png)'}}>
          <img src="https://reports.yipitdata.com/YIPITDATA/data/YDL-Logo-c82df2d4.png" style={{width:100}} />
          <h3 className="white">{this.state.post.attributes.title}</h3>
          <p className="white subheading">{this.state.post.attributes.date.toString()}</p>
        </div>
        <div className="max-width-container shadow white-background tiny-padding" style={{marginTop:-50, maxWidth:1400}}>
          {this.state.sections && this.state.sections.map((data) =>{
            if(data.attributes.type == "image" && data.attributes.image){
              return <img className="full-width" style={{cursor:'pointer'}} src={data.attributes.image.url()} onClick={() => this.showImage(data.attributes.image.url())}  />
            } else if(data.attributes.type == "chart" && data.attributes.image){
              return (
                <div className="section-margin">
                  <h5 style={{marginBottom:"15px"}}><span style={{color:"#0d4753"}}>{data.attributes.exhibit ? 'Exhibit '+data.attributes.exhibit : ""} </span>{data.attributes.title ? data.attributes.title : ""}</h5>
                  <img className="full-width" style={{cursor:'pointer'}} src={data.attributes.image.url()} onClick={() => this.showImage(data.attributes.image.url())} />
                  {data.attributes.note && <p className="subheading">{data.attributes.note}</p>}
                </div>
              )
            } else if(data.attributes.type == "title" && data.attributes.title){
              return (
                <div className="section-margin">
                  <h4>{data.attributes.title}</h4>
                </div>
              )
            } else{
              return <div><div className="blog-content" dangerouslySetInnerHTML={{__html:data.attributes.content}} /></div>
            }
          })}
        </div>
        {this.state.isOpen &&
          <PortalModal
            modalActive={this.state.isOpen}
            modalHidden={this.hideImage}
            maxWidthOverride={true}
            content={
              <div className="full-height full-width report-detail-modal white-background tiny-padding">
                <img className={"image-background-preload"} src={this.state.fullScreenImage} onLoad={this.onFullScreenImageLoad} />
                <div
                  className={"full-height full-width background-image-contain full-screen-image "+fullScreenImageLoaded}
                  style={{backgroundImage:'url('+this.state.fullScreenImage+')'}}>
                </div>
            </div>
        }
        />
      }
      </div>
    )
  }
}

export default withRouter(BlogHome)
