import React, { Component } from 'react';
import Parse from 'parse';
import { Link , Redirect, withRouter} from "react-router-dom";
import {CheckVal} from './../../utils/functions';

class Login extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        password : null
      })
  }
  login = async() => {
    const user = await Parse.User.logIn(this.state.username, this.state.password);
    if(user && user.id == "tbCXL6teGO"){
      this.setState({
        login:true
      })
    } else if(user && user.id == "CmJGxFks9C"){
      this.setState({
        login:true
      })
    } else{
      alert('Cmon guy!!!! wrong password sheesh')
    }
  }


  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.login()
    }
  }
  usernameUpdate = (e) => {
    this.setState({username:e.target.value});
  }
  passwordUpdate = (e) => {
    this.setState({password:e.target.value});
  }

  render() {
    if(this.state.login){
      if(window.location.href && window.location.href.indexOf('yipit-website-admin') != -1){
        return <Redirect to='/' />
      } else{
        return <Redirect to='/admin' />
      }
    }
    return (
      <div className="section-padding full-height" style={{backgroundImage:'url(/images/triangle-texture.png)'}}>
        <div className="small-max-width-container shadow medium-padding" style={{backgroundColor:'rgba(0,0,0,.3)'}}>
          <div className="centered-text">
            <img className="half-width" style={{maxWidth:'200px'}} src="./images/logo-light.png" />
          </div>
          <div className="tiny-padding">
            <input
              style={{padding:"16px"}}
              type="username"
              className="full-width light-grey-background"
              value={this.state.username}
              onChange={this.usernameUpdate}
              onKeyPress={this.handleKeyPress}
              placeholder={'username'} />
          </div>
          <div className="tiny-padding">
            <input
              style={{padding:"16px"}}
              type="password"
              className="full-width light-grey-background"
              value={this.state.password}
              onChange={this.passwordUpdate}
              onKeyPress={this.handleKeyPress}
              placeholder={'Password'} />
          </div>
          <div className="tiny-padding">
            <div className="button primary-background white full-width tiny-padding" style={{margin:'0px', padding:"20px"}} onClick={this.login}>{'Login'}</div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)
