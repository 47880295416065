import React, { Component } from 'react';
import { AddToClipBoard } from './../../utils/functions';
import {
  BrandColors,
  ProductColors,
  CategoricalColors,
  generateSequentialPalette,
  generateDivergingPalette,
} from './../../utils/designConstants';
import ColorSquare from './colorSquare';
import DesignSection from './designSection';

export default class VisualizationSection extends Component {
  constructor() {
    super();

    this.defaultSequentialSteps = 6;
    this.defaultDivergingSteps = 6;

    this.state = {
      sequentialColor: CategoricalColors[0],
      sequentialSteps: this.defaultSequentialSteps,
      divergingColorOne: CategoricalColors[0],
      divergingColorTwo: CategoricalColors[1],
      divergingSteps: this.defaultDivergingSteps,
    };
  }

  updateSequentialColor = (e) => {
    if (e.target.value) {
      this.setState({ sequentialColor: CategoricalColors[e.target.value] });
    }
  };

  updateSequentialSteps = (e) => {
    if (e.target.value) {
      this.setState({ sequentialSteps: parseInt(e.target.value) });
    }
  };

  updateDivergingColor = (e, order) => {
    if (e.target.value) {
      if (order === 1) {
        this.setState({ divergingColorOne: CategoricalColors[e.target.value] });
      } else {
        this.setState({ divergingColorTwo: CategoricalColors[e.target.value] });
      }
    }
  };

  updateDivergingSteps = (e) => {
    console.log(e.target.value);

    if (e.target.value) {
      this.setState({ divergingSteps: parseInt(e.target.value) });
    }
  };

  render() {
    const {
      sequentialColor,
      sequentialSteps,
      divergingColorOne,
      divergingColorTwo,
      divergingSteps,
    } = this.state;

    const sequentialPickerNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const divergingPickerNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

    const sequentialPalette = generateSequentialPalette(sequentialColor, sequentialSteps);
    const divergingPalette = generateDivergingPalette(
      divergingColorOne,
      divergingColorTwo,
      divergingSteps
    );

    return (
      <div className="full-width sectionContainer">
        <DesignSection
          title={'Brand Colors'}>
          <div className="stretch-row full-width" style={{ overflowX: 'auto' }}>
            {BrandColors.map((color, index) => (
              <ColorSquare color={color} key={color + index} />
            ))}
          </div>
        </DesignSection>
        <DesignSection
          title={'Product Family Colors'}
          subtitle={'Product groups have been formed to help organize our product line. Each group is represented by a color'}>
          <div className="stretch-row full-width" style={{ overflowX: 'auto' }}>
            {ProductColors.map((color, index) => (
              <ColorSquare color={color} key={color + index} />
            ))}
          </div>
        </DesignSection>
      {/*
        <DesignSection
          title={'Categorical Colors'}
          subtitle={"Categorical color palettes are used to distinguish a logical separation. We want these colors to look very different from one another to clearly delineate groups. These colors should be able to exist in a group without signifying increasing or decreasing values."}>
          <div className="row">
            <div
              className="stretch-row full-width"
              style={{ overflowX: 'auto' }}>
              {CategoricalColors.map((color, index) => (
                <ColorSquare color={color} key={color + index} />
              ))}
            </div>
          </div>
        </DesignSection>
        <DesignSection
          title={'Sequential Colors'}
          subtitle={'Sequential color palette should be used to establish a visual heirarchy. We want these colors to tell a story using the color stops as a way to better illustrate importance. Choose a color below to create a base and the amount of steps you need and we will generate the appropriate palette for the visualization.'}>
          <div className="row">
            <Picker
              subheading={'Color'}
              onChange={this.updateSequentialColor}
              style={{ marginRight: '20px' }}>
              {CategoricalColors.map((color, index) => {
                return (
                  <option value={index} key={color.name}>
                    {color.name}
                  </option>
                );
              })}
            </Picker>
            <Picker
              defaultValue={this.defaultSequentialSteps}
              subheading={'Steps'}
              onChange={this.updateSequentialSteps}
              style={{ marginRight: '20px' }}>
              {sequentialPickerNumbers.map((val, index) => {
                return (
                  <option value={val} key={val}>
                    {val}
                  </option>
                );
              })}
            </Picker>
          </div>
          <div className="stretch-row full-width" style={{ overflowX: 'auto' }}>
            {sequentialPalette.map((color, index) => (
              <ColorSquare color={color} key={color + index} />
            ))}
          </div>
        </DesignSection>
        <DesignSection
          title={'Diverging Colors'}
          subtitle={'Diverging color palettes are cool.'}>
          <div className="row full-width">
            <Picker
              subheading={'Color One'}
              onChange={(e) => this.updateDivergingColor(e, 1)}
              defaultValue={0} // blue is the default and it's the first option
              style={{ marginRight: '20px' }}>
              {CategoricalColors.map((color, index) => {
                return (
                  <option value={index} key={color.name}>
                    {color.name}
                  </option>
                );
              })}
            </Picker>
            <Picker
              subheading={'Color Two'}
              onChange={(e) => this.updateDivergingColor(e, 2)}
              defaultValue={1} // ornage is the default and it's the second option
              style={{ marginRight: '20px' }}>
              {CategoricalColors.map((color, index) => {
                return (
                  <option value={index} key={color.name}>
                    {color.name}
                  </option>
                );
              })}
            </Picker>
            <Picker
              subheading={'Steps'}
              onChange={this.updateDivergingSteps}
              style={{ marginRight: '20px' }}
              defaultValue={this.defaultDivergingSteps}>
              {divergingPickerNumbers.map((val, index) => {
                return (
                  <option value={val} key={val}>
                    {val}
                  </option>
                );
              })}
            </Picker>
          </div>
          <div className="stretch-row full-width medium-top-margin" style={{ overflowX: 'auto' }}>
            {divergingPalette.map((color, index) => (
              <ColorSquare color={color} key={color + index} />
            ))}
          </div>
        </DesignSection>
      */}
      </div>
    );
  }
}

function Picker({ subheading, onChange, style, defaultValue, children }) {
  return (
    <div style={style}>
      <h7 className="subheading uppercase bold-text" style={{display:"block", marginBottom:'10px'}}>{subheading}</h7>
      <select
        defaultValue={defaultValue ? defaultValue.toString() : null}
        onChange={(e) => onChange(e)}
        style={{ marginRight: '20px', marginBottom:'20px' }}
      >
        {children}
      </select>
    </div>
  );
}
