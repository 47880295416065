import React, { Component } from 'react';
import {Colors} from './../../utils/constants';
import {Link} from "react-router-dom";
import FullPageBreak from './../../components/fullPageBreak';
import {Helmet} from "react-helmet";
import ColorSection from './colorSection';
import FontSection from './fontSection';
import LogoSection from './logoSection';
import PowerpointSection from './powerpointSection';
import LetterheadSection from './letterheadSection';
import ImagesSection from './imagesSection';
import EmailSection from './emailSection';
import ChartSection from './visualizationSection';
import BiosSection from './biosSection';
import SigmaSection from './sigmaSection';
import './design-dashboard.css';

export default class DesignDashboard extends Component {
  constructor(props) {
      super(props);
      this.state={
        mobileMenuActive:false
      };
  }
  componentDidMount = async() =>{

  }
  toggleMobileMenu = () =>{
    let mobileMenuActive;
    if(this.state.mobileMenuActive){
      this.setState({
        mobileMenuActive:false
      })
    } else{
      this.setState({
        mobileMenuActive:true
      })
    }
  }

  render() {
    let { page } = this.props.match.params;
    let mobileMenuActiveClass = this.state.mobileMenuActive ? 'mobile-menu-open' :'';
    return (
      <div className="full-height full-width">
        <Helmet>
          <title>Electric Design | Yipit Data</title>
          <meta name="description" content="The theory behind Edison's attempt to make the internet a more beautiful place." />
          <link type="text/css" media="screen" rel="stylesheet" href="https://trends.edison.tech/assets/twentytwenty/css/twentytwenty.css"/>
        </Helmet>

        <div className={"design-dashboard-container "+mobileMenuActiveClass}>
          <div className="mobile-menu-icon" onClick={this.toggleMobileMenu}>
            <img alt="mobile-menu-icon" className="design-dashboard-mobile-menu-icon primary-background" src={"/images/menu-white.png"} />
          </div>
          <div className="design-dashboard-navigation" onClick={this.toggleMobileMenu}  style={{backgroundImage:'url(/images/triangle-texture.png)'}}>
            <Link name="Home" to="/">
              <img alt="Edison Software white logo" className="design-dashboard-navigation-logo" src={"/images/logo-light.png"} />
            </Link>
            <div className={page == 'logo' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/logo">{'Logo'}</Link>
            </div>
            <div className={page == 'colors' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/colors">{'Colors'}</Link>
            </div>
            <div className={page == 'font' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/font">{'Font'}</Link>
            </div>
            <div className={page == 'slides' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/slides">{'Slides'}</Link>
            </div>
            <div className={page == 'letterhead' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/letterhead">{'Letterhead'}</Link>
            </div>
            <div className={page == 'imagery' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/imagery">{'Imagery'}</Link>
            </div>
            <div className={page == 'email' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/email">{'Email Signatures'}</Link>
            </div>
            <div className={page == 'charts' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/charts">{'Charts'}</Link>
            </div>
            <div className={page == 'sigma' ? 'design-dashboard-navigation-item active' : 'design-dashboard-navigation-item'}>
              <Link to="/design/sigma">{'Sigma'}</Link>
            </div>
          </div>
          <div className="design-dashboard-content">
            {!page &&
              <div id="hero" className="full-height">
                <div className="small-padding full-height">
                  <div className="max-width-container centered-text full-height">
                    <div style={{display:'flex', alignItems:'center', justifyContent:'center'}} className="medium-padding full-height">
                      <div className="full-width">
                        <h2 className="full-width primary-color" style={{marginTop:0}}><span className="primary-color">{'Design'}</span><span>{" System"}</span></h2>
                        <div className="small-max-width-container">
                          <h4>{'Design is a conversation, not a monologue, and “we’ve always done it this way” is never an acceptable answer. Design shapes values and can help change the future. Use responsibly.'}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            {page == "logo" &&
              <div>
                <LogoSection />
              </div>
            }
            {page == "colors" &&
              <div>
                <ColorSection />
              </div>
            }
            {page == "font" &&
              <div>
                <FontSection />
              </div>
            }
            {page == "slides" &&
              <div>
                <PowerpointSection />
              </div>
            }
            {page == "letterhead" &&
              <div>
                <LetterheadSection />
              </div>
            }
            {page == "email" &&
              <div>
                <EmailSection />
              </div>
            }
            {page == "imagery" &&
              <div>
                <ImagesSection />
              </div>
            }
            {page == "charts" &&
              <div>
                <ChartSection />
              </div>
            }
            {page == "sigma" &&
              <div>
                <SigmaSection />
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}
