import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Parse from 'parse';
import { sortable } from 'react-sortable';

export default class SortableList extends Component {
  state = {
    items: this.props.parselist,
		parseclass: this.props.parseclass
  };

  onSortItems = (items) => {
		var parseclass = this.state.parseclass;
		this.setState({
      items: items
    });
  }
  onSortEnd = async () => {
    this.setState({
      loading:true
    })
    var parseclass = this.state.parseclass;
    var items = this.state.items;
    items.map(async(item, i) => {
      var index = i;
      console.log(i)
      const Query = Parse.Object.extend(parseclass);
      const query = new Parse.Query(Query);
      const result = await query.get(item.id);
      console.log(index)
      result.set('order',index)
      result.save();
      if(index == items.length -1){
        this.setState({
          loading:false
        })
      }
    })
  }
  render() {
    const { items } = this.state;
    console.log(items)
    var listItems = items.map((item, i) => {
      var content = item.attributes[this.props.parselabelkey]
      if(this.props.onclickpath){
        var link = this.props.onclickpath+item[this.props.onclickkey];
        content = <NavLink to={link} className="black-color">
          <h4 style={{margin:0, marginBottom:15,marginTop:15}}>{item.attributes[this.props.parselabelkey]}</h4>
          {this.props.parsesublabelkey &&
            <span>
              <br />
              <i className="subheading">{item.attributes[this.props.parsesublabelkey]}</i>
            </span>
          }
        </NavLink>;
      }
      return (
        <SortableItem
          key={i}
          onSortItems={this.onSortItems}
          items={items}
          sortId={i}>
					<div>
						<div className="white-background" style={{borderBottom:'1px solid #eee'}}>
							<div className="row">
								<img draggable="false" style={{height:30, width:30, marginRight:20, marginTop:18}} src="/images/reorder-icon.png" alt="reorder" />
                {content}
							</div>
						</div>
					</div>
				</SortableItem>
      );
    });
    if(this.state.loading) return <div>saving</div>
    return (
      <div style={{textAlign:'right'}}>
        <div className="button primary-background white" onClick={this.onSortEnd}>{'Save Order'}</div>
        <div className='sortable-list'>
          {listItems}
        </div>
      </div>
    )
  }
};

class Item extends React.Component {
  render() {
    return (
      <div {...this.props}>
        {this.props.children}
      </div>
    )
  }
}


var SortableItem = sortable(Item);
