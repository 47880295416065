import React, { Component } from 'react';
import Parse from 'parse';

export default class ParseEditableInput extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        value:this.props.parsevalue
      })
  }

  onChange = async (e) => {
    let value = e.target.value;
    this.setState({
      value:value
    })
  }
  onBlur = async (e) =>{
    let parseclass = this.props.parseclass;
    let parseobjectid = this.props.parseobjectid;
    let parsekey = this.props.parsekey;

    const ParseClass = Parse.Object.extend(parseclass);
    const parseClassQuery = new Parse.Query(ParseClass);
    const result = await parseClassQuery.get(parseobjectid);
    result.set(parsekey,this.state.value)
    result.save();
  }

  render() {
    return (
      <input
        className="full-width light-grey-background tiny-padding"
        type="text"
        value={this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholder="..." />
    );
  }
}
