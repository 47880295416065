import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {ListEmployeeNavigatorCategories} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';

export default class AdminEmployeeNavigatorCategoryList extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let items = await ListEmployeeNavigatorCategories("admin");
    this.setState({
      items:items
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <div className="small-12 medium-10">
                <h2 className="white">{'Categories'}</h2>
              </div>
              <div className="small-12 medium-2 row" style={{alignItems:'center'}}>
                <Link to={"/admin/category/add"}>
                  <div className="full-width button primary-color white-background">{'Add New'}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="max-width-container" style={{marginLeft:0}}>
            {this.state.items && this.state.items.map((item) =>{
              let name = item.attributes.name;
              let description = item.attributes.description;
              if(description && description.length > 300){
                description = description.substring(0,300);
                description = description+'...'
              }
              let opacity = 1;
              if(!item.attributes.status){
                opacity = .5;
              }
              return(
                <Link to={'/admin/category/'+item.id}>
                  <div className="row tiny-padding small-margin-bottom border-bottom full-width" style={{opacity:opacity}}>
                    <div>
                      {name && <h4>{name}</h4>}
                      {description && <p className="subheading">{description}</p>}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
