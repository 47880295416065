import React, { Component } from 'react';
import "./blogSection.css";
import { Editor } from '@tinymce/tinymce-react';
import Parse from 'parse';
import {GetUrlVariable} from './../../utils/functions';

export default class BlogSection extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  deleteSection = async() => {
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("deleted",true);
    let blogPost = await post.save();
    this.setState({
      item:blogPost
    })
  }
  updateType = async(type) => {
    this.setState({
      type:type
    })
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("type",this.state.type);
    let blogPost = await post.save();
    this.setState({
      item:blogPost
    })
  }
  handleEditorChange = async(content) => {
    this.setState({
      content:content
    })
  }
  save = async() => {
    console.log(this.state.content)
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("content",this.state.content);
    let blogPost = await post.save();
  }
  updateImage = async(image) => {
    this.setState({
      image:image
    })
    if(this.props.editable){
      var posts = Parse.Object.extend("YipitReportSections");
      var query = new Parse.Query(posts);
      let post = await query.get(this.state.id)
      post.set("image",this.state.image);
      let blogPost = await post.save();
      this.setState({
        item:blogPost
      })
    }
  }
  componentDidMount = () =>{
    this.initScreen();
  }
  initScreen = () =>{

    let item = this.props.item;
    let content = item.attributes.content;
    let image = item.attributes.image;
    this.setState({
      id:item.id,
      item:item,
      content:content
    })
  }
  render() {
    let item = this.state.item;
    if(!item) return null;
    let editable = this.props.editable;
    let layout = item.attributes.type;
    if(item.attributes.deleted) return null;
    return (
      <div className="report-text-section section-wrapper">
        <div className={editable ? 'editable' : ''}>
           {(editable == true && !layout) &&
             <div className="row">
              <div className="small-12 medium-6" onClick={()=>this.updateType('text')}>
                {'Text'}
              </div>
              <div className="small-12 medium-6" onClick={()=>this.updateType('image')}>
                {'Image'}
              </div>
             </div>
           }
           {(editable == false && layout == 'image' && item.attributes.image) &&
            <img className="full-width" src={item.attributes.image.url()} />
           }
           {(editable == true && layout == 'image') &&
            <div className="blog-image-wrapper-container">
              <div className="blog-image-upload-hover-container">{'Upload'}</div>
              <img className="full-width" src={item.attributes.image ? item.attributes.image.url() : '/images/rectangle.png'} />
            </div>
           }
           {(editable == false && layout == 'text') &&
             <div className="text-wrapper-container" dangerouslySetInnerHTML={this.state.content}>
             </div>
           }
           {(editable == true && layout == 'text') &&
             <div className="text-wrapper-container">
             <Editor
                 apiKey="26055jekh5zm6xq0ouhcde5pw48javo0i078gbl5xx4bn7tb"
                 value={this.state.content}
                 init={{
                   branding: false,
                   min_height: 500,
                   menubar: false,
                   plugins: "autoresize,media",
                   autoresize_bottom_margin: 50,
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help | media'
                 }}
                 onEditorChange={this.handleEditorChange}
               />
             </div>
           }
           <div className="white-background tiny-padding right-text">
             <div className="button black-background white" onClick={this.save} style={{marginRight:10}}>{'Save'}</div>
             <div className="button black-background white" onClick={this.deleteSection}>{'Delete'}</div>
          </div>
        </div>
      </div>
    );
  }
}
