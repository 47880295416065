import React, { Component } from 'react';
import {Link, Redirect,useParams,withRouter} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {GetCoverageSummaryProduct,DeleteDbItem,UpdateDbItem} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';
import ParseEditableInput from './../../components/adminFormFields/parseEditableInput';
import ParseEditableBoolean from './../../components/adminFormFields/parseEditableBoolean';
import ParseEditableTextarea from './../../components/adminFormFields/parseEditableTextarea';
import ParseRelationalArray from './../../components/adminFormFields/parseRelationalArray';

export class AdminCoverageSummaryEditProduct extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let { id } = this.props.match.params;
    let item = await GetCoverageSummaryProduct(id);
    this.setState({
      item:item
    })
  }
  delete = async() =>{
    var response = window.confirm("Are you sure you want to delete!");
    if (response == true) {
      await DeleteDbItem(this.state.item.id,'CoverageProducts')
      this.props.history.goBack()
    } else {
      return false
    }
  }
  onHexColorChange = async (e) =>{
    let value = e.target.value;
    if(value && value.indexOf('#') == -1){
      value = '#'+value
    }
    let result = await UpdateDbItem(this.state.item.id,'CoverageProducts',[{key:'color',val:value}])
    this.setState({
      item:result
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <h2 className="white" style={{marginBottom:'0px'}}>{this.state.item && this.state.item.attributes.name}</h2>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="max-width-container" style={{marginLeft:0}}>
            {this.state.item &&
              <div>
                <div className="small-top-margin">
                  <h5>{'Product Name'}</h5>
                  <ParseEditableInput
                    parseclass="CoverageProducts"
                    parseobjectid={this.state.item.id}
                    parsekey="name"
                    parsevalue={this.state.item.attributes.name}
                    placeholder="Name of product" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Product Subheading'}</h5>
                  <ParseEditableInput
                    parseclass="CoverageProducts"
                    parseobjectid={this.state.item.id}
                    parsekey="subheading"
                    parsevalue={this.state.item.attributes.subheading}
                    placeholder="subheading of product" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Description'}</h5>
                  <ParseEditableTextarea
                    parseclass="CoverageProducts"
                    parseobjectid={this.state.item.id}
                    parsekey="description"
                    parsevalue={this.state.item.attributes.description}
                    placeholder="description of product" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Related Companies'}</h5>
                  <ParseRelationalArray
                    parseclass={'CoverageProducts'}
                    parsevaluesclass={'CoverageCompanies'}
                    parseobjectid={this.state.item.id}
                    parsekey="companies"
                    parsevalue={this.state.item.attributes.companies}
                  />
                </div>
                <div className="small-top-margin">
                  <h5>{'Hide Ticker From Coverage Summary'}</h5>
                  <ParseEditableBoolean
                    parseclass="CoverageProducts"
                    parseobjectid={this.state.item.id}
                    parsekey="hideTickerFromCoverageSummary"
                    parsevalue={this.state.item.attributes.hideTickerFromCoverageSummary}
                   />
                </div>
                <div className="small-top-margin">
                  <h5>{'Status'}</h5>
                  <ParseEditableBoolean
                    parseclass="CoverageProducts"
                    parseobjectid={this.state.item.id}
                    parsekey="status"
                    parsevalue={this.state.item.attributes.status}
                   />
                </div>
                <div className="medium-top-margin">
                  <h5>{'Danger Zone'}</h5>
                  <div onClick={this.delete} className="button red-background white">{'Delete'}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AdminCoverageSummaryEditProduct)
