import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {ListEmployeeNavigatorLinks, ListEmployeeNavigatorCategories} from './../../utils/dbFunctions';
import {TruncateDescription} from './../../utils/functions';
import MaterialIcon from 'material-icons-react';
import {Helmet} from "react-helmet";

export default class EmployeeNavigator extends Component {
  constructor(props) {
      super(props);
      this.state={
        updateKey:0
      };
  }
  componentDidMount = async() =>{
    let links = await ListEmployeeNavigatorLinks();
    let categories = await ListEmployeeNavigatorCategories();
    this.setState({
      allLinks:links,
      links:links,
      categories:categories,
      allCategories:categories,
    },()=>{
      this.onSearch()
    })
  }

  onSearch = (e) =>{
    let value = null;
    let linkResults = [];
    let results = [];
    if(e){
      value = e.target.value;
    }

    if(value){
      value = value.toLowerCase();
      if(value != this.state.value){
        linkResults = this.state.allLinks && this.state.allLinks.filter((link) => {
          let name = link.attributes.name;
          let description = link.attributes.description;
          if(name){
            name = name.toLowerCase();
          }
          if(description){
            description = description.toLowerCase();
          }

          return (
            (name && name.indexOf(value)!= -1) ||
            (description && description.indexOf(value)!= -1)
          )
        })
      }
    } else{
      linkResults = this.state.allLinks;
    }

    if(this.state.selectedCategories && this.state.selectedCategories.length > 0){
      let relatedLinks = [];
      for (let i = 0; i < this.state.selectedCategories.length; i++) {
        let categoryArray = this.state.categories.filter((category) => category.id == this.state.selectedCategories[i]);
        if(categoryArray && categoryArray[0]){
          relatedLinks = relatedLinks.concat(categoryArray[0].attributes.links);
        }
      }
      linkResults = linkResults.filter((link) => relatedLinks.indexOf(link.id) != -1)
    }
    for (let i = 0; i < linkResults.length; i++) {
      let item = linkResults[i];
      item.type = 'link'
      results.push(item)
    }
    results = results.sort((a, b) =>{
        let aName = a.attributes.name;
        let bName = b.attributes.name;
        if(aName < bName) { return -1; }
        if(aName > bName) { return 1; }
        return 0;
    })

    this.setState({
      results:results,
      links:linkResults,
      value:value,
      updateKey:this.state.updateKey+1
    })
  }


  resetModals = () =>{

  }

  onCategoryClick = (item) =>{
    let selectedCategories = this.state.selectedCategories;
    if(!selectedCategories){
      selectedCategories = [];
    }
    let index = selectedCategories.indexOf(item.id);
    if(index != -1){
      selectedCategories.splice(index, 1);
    } else{
      selectedCategories.push(item.id)
    }
    this.setState({
      selectedCategories:selectedCategories
    },()=>{
      this.onSearch()
    })
  }

  render() {
    console.log(this.state.results)
    return (
      <div className="full-height full-width">

        <Helmet>
          <title>YipitData — Employee Navigator</title>
        </Helmet>
        <div className="small-padding primary-background centered-text design-site-mobile-hero-padding"  style={{backgroundImage:'url(/images/triangle-texture.png)'}}>
          <div className="small-max-width-container" style={{ paddingTop:70, paddingBottom:80}}>
            <img className="half-width" style={{maxWidth:80}} src="/images/navigator-icon.png" />
            <h3 className="white" style={{marginTop:20, marginBottom:10}}>{'Employee Navigator'}</h3>
            <p className="white subheading" style={{marginBottom:40}}>{'A place for employees to find answers'}</p>
            <div style={{position:'relative'}}>
              <input className="tiny-padding full-width employee-navigator-input" placeholder="Search" onChange={this.onSearch} val={this.state.value} />
              <div className="employee-navigator-input-search-icon-wrapper">
                <MaterialIcon
                  className="material-icons employee-navigator-input-search-icon"
                  icon={'search'}
                  size={'30'}
                  color={'#fff'} />
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div key={this.state.updateKey} className="max-width-container">
            <div>
              <div className="tiny-padding">
                {this.state.categories && this.state.categories.map((item, index) =>{
                  let decorativeClass = 'label';
                  if(this.state.selectedCategories && this.state.selectedCategories.indexOf(item.id) != -1){
                    decorativeClass = 'label primary-background white';
                  }
                  return(<div className={decorativeClass} style={{cursor:'pointer'}} onClick={()=>this.onCategoryClick(item)}><b>{item.attributes.name}</b></div>)
                })}
                </div>
                <div className="row">
                  {this.state.results && this.state.results.map((item, index) =>{
                    return(
                      <div className="small-12 medium-6 large-4 tiny-padding">
                        <div className="card full-height">
                          <a target="_blank" href={item.attributes.link}>
                            <div className="row full-height">
                              <div className="small-12 medium-2 primary-background" style={{display:'flex',alignItems:'center', justifyContent:'center'}}>
                                <MaterialIcon
                                  className="material-icons"
                                  icon={'link'}
                                  size={'50'}
                                  color={'#fff'} />
                              </div>
                              <div className="small-12 medium-9" style={{padding:15}}>
                                {item.attributes.name && <p style={{marginBottom:0, color:'#000'}}><b>{item.attributes.name}</b></p>}
                                {item.attributes.description && <p style={{ color:'#000'}} className="subheading">{item.attributes.description}</p>}
                                {item.attributes.link && <h6 style={{display:'none', marginTop:6}}><a href={item.attributes.link}>{'Link'}</a></h6>}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    )
                  })}
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
