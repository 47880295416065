import React, { Component } from 'react';
import ScrollFadeIn from './../../components/scrollFadeIn';
import {Colors} from './../../utils/constants';
import {
  BrandColors,
  ProductColors,
  CategoricalColors,
  generateSequentialPalette,
  generateDivergingPalette,
} from './../../utils/designConstants';
import ColorSquare from './colorSquare';
import DesignSection from './designSection';

export default class VisualizationSection extends Component {
  constructor() {
    super();

    this.defaultSequentialSteps = 6;
    this.defaultDivergingSteps = 6;

    this.state = {
      sequentialColor: CategoricalColors[0],
      sequentialSteps: this.defaultSequentialSteps,
      divergingColorOne: CategoricalColors[0],
      divergingColorTwo: CategoricalColors[1],
      divergingSteps: this.defaultDivergingSteps,
    };
  }

  updateSequentialColor = (e) => {
    if (e.target.value) {
      this.setState({ sequentialColor: CategoricalColors[e.target.value] });
    }
  };

  updateSequentialSteps = (e) => {
    if (e.target.value) {
      this.setState({ sequentialSteps: parseInt(e.target.value) });
    }
  };

  updateDivergingColor = (e, order) => {
    if (e.target.value) {
      if (order === 1) {
        this.setState({ divergingColorOne: CategoricalColors[e.target.value] });
      } else {
        this.setState({ divergingColorTwo: CategoricalColors[e.target.value] });
      }
    }
  };

  updateDivergingSteps = (e) => {
    console.log(e.target.value);

    if (e.target.value) {
      this.setState({ divergingSteps: parseInt(e.target.value) });
    }
  };

  render() {
    const {
      sequentialColor,
      sequentialSteps,
      divergingColorOne,
      divergingColorTwo,
      divergingSteps,
    } = this.state;

    const sequentialPickerNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const divergingPickerNumbers = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19];

    const sequentialPalette = generateSequentialPalette(sequentialColor, sequentialSteps);
    const divergingPalette = generateDivergingPalette(
      divergingColorOne,
      divergingColorTwo,
      divergingSteps
    );

    return (
      <div className="full-width sectionContainer">
      <DesignSection
        title={'Categorical Colors'}
        subtitle={"Categorical color palettes are used to distinguish a logical separation. We want these colors to look very different from one another to clearly delineate groups. These colors should be able to exist in a group without signifying increasing or decreasing values."}>
        <div className="row">
          <div
            className="stretch-row full-width"
            style={{ overflowX: 'auto' }}>
            {CategoricalColors.map((color, index) => (
              <ColorSquare color={color} key={color + index} />
            ))}
          </div>
        </div>
      </DesignSection>
      <DesignSection
        title={'Sequential Colors'}
        subtitle={'Sequential color palette should be used to establish a visual heirarchy. We want these colors to tell a story using the color stops as a way to better illustrate importance. Choose a color below to create a base and the amount of steps you need and we will generate the appropriate palette for the visualization.'}>
        <div className="row">
          <Picker
            subheading={'Color'}
            onChange={this.updateSequentialColor}
            style={{ marginRight: '20px' }}>
            {CategoricalColors.map((color, index) => {
              return (
                <option value={index} key={color.name}>
                  {color.name}
                </option>
              );
            })}
          </Picker>
          <Picker
            defaultValue={this.defaultSequentialSteps}
            subheading={'Steps'}
            onChange={this.updateSequentialSteps}
            style={{ marginRight: '20px' }}>
            {sequentialPickerNumbers.map((val, index) => {
              return (
                <option value={val} key={val}>
                  {val}
                </option>
              );
            })}
          </Picker>
        </div>
        <div className="stretch-row full-width" style={{ overflowX: 'auto' }}>
          {sequentialPalette.map((color, index) => (
            <ColorSquare color={color} key={color + index} />
          ))}
        </div>
      </DesignSection>
      <DesignSection
        title={'Diverging Colors'}
        subtitle={'Diverging color palettes are cool.'}>
        <div className="row full-width">
          <Picker
            subheading={'Color One'}
            onChange={(e) => this.updateDivergingColor(e, 1)}
            defaultValue={0} // blue is the default and it's the first option
            style={{ marginRight: '20px' }}>
            {CategoricalColors.map((color, index) => {
              return (
                <option value={index} key={color.name}>
                  {color.name}
                </option>
              );
            })}
          </Picker>
          <Picker
            subheading={'Color Two'}
            onChange={(e) => this.updateDivergingColor(e, 2)}
            defaultValue={1} // ornage is the default and it's the second option
            style={{ marginRight: '20px' }}>
            {CategoricalColors.map((color, index) => {
              return (
                <option value={index} key={color.name}>
                  {color.name}
                </option>
              );
            })}
          </Picker>
          <Picker
            subheading={'Steps'}
            onChange={this.updateDivergingSteps}
            style={{ marginRight: '20px' }}
            defaultValue={this.defaultDivergingSteps}>
            {divergingPickerNumbers.map((val, index) => {
              return (
                <option value={val} key={val}>
                  {val}
                </option>
              );
            })}
          </Picker>
        </div>
        <div className="stretch-row full-width medium-top-margin" style={{ overflowX: 'auto' }}>
          {divergingPalette.map((color, index) => (
            <ColorSquare color={color} key={color + index} />
          ))}
        </div>
      </DesignSection>
        <div className="max-width-container">
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 1: "}</span>
                {"Use different colors only when they correspond to differences of meaning in the data."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row">
                  <div
                    className="small-12 medium-6 full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-2.png'} className="full-width" />
                    </div>
                  </div>
                  <div
                    className="small-12 medium-6 centered-text full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #ff2d55"}}>
                      <img src={'/design/rules-chart-1.png'} className="full-width" />
                    </div>
                  </div>
                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 2: "}</span>
                {"Don't include too much detail. Eliminate the unnecessary so you can focus on what's important."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row">
                  <div
                    className="small-12 medium-6 full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-5.png'} className="full-width" />
                    </div>
                  </div>
                  <div
                    className="small-12 medium-6 centered-text full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #ff2d55"}}>
                      <img src={'/design/rules-chart-4.png'} className="full-width" />

                    </div>
                  </div>
                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 3: "}</span>
                {"When displaying sequential values, stick with a single hue and vary intensity (pale colors for low values and bright colors for high values)."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row">
                  <div
                    className="small-12 medium-4 full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-6.png'} className="full-width" />
                    </div>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-7.png'} className="full-width" />
                    </div>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-8.png'} className="full-width" />
                    </div>
                  </div>
                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 4: "}</span>
                {"Use bright colors to focus on the information you want to highlight."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row" style={{justifyContent: "center"}}>
                  <div
                    className="small-12 medium-6 full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-9.png'} className="full-width" />
                    </div>
                  </div>

                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 5: "}</span>
                {"Use a diverging palette to show a range of values that are above or below some logical breakpoint in the middle."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row" style={{justifyContent: "center"}}>
                  <div
                    className="small-12 medium-6 full-height small-padding">
                    <div className="small-padding" style={{border: "2px solid #4cd964"}}>
                      <img src={'/design/rules-chart-10.png'} className="full-width" />
                    </div>
                  </div>

                </div>
              </ScrollFadeIn>
            </div>
          </div>
        <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Rule 6: "}</span>
                {"Sometimes it's okay to break the rules. Any of these rules can be broken under justified conditions."}
              </h3>
            </div>
            <div className="shadow">
              <ScrollFadeIn>
                <div className="row primary-background" style={{justifyContent: "center", alignItems: "center", height:'70vh'}}>
                  <div className="three-quarter-width">
                    <h2 className="full-width white centered-text">{'Know the rules well, so you can break them effectively'}</h2>
                    <h4 className="full-width white centered-text">{'Dali Lama XIV'}</h4>
                  </div>
                </div>
              </ScrollFadeIn>
            </div>
          </div>
          <div className="medium-top-margin medium-bottom-margin">
            <div className="small-padding" style={{maxWidth: "1200px"}}>
              <h3 className="dark-grey-color">
                <span className="primary-color">{"Choosing A Chart: "}</span>
                {"Choosing the right chart type is key when presenting impactful data."}
              </h3>
            </div>
            <ScrollFadeIn>
              <div className="row">
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                    <img src={'/design/chart-line.png'} className="full-width" />
                    <h4>{'Line Chart'}</h4>
                    <p className="subheading">{"Particularly well suited for displaying changes over time. Line charts don’t have to start from zero, and can be augmented with labels and (linear) trends. Add 1 or 2 categorical features with few levels by building a facet grid. For only a few time measurements consider a bar plot."}</p>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                  <img src={'/design/chart-area.png'} className="full-width" />
                  <h4>{'Area Chart'}</h4>
                  <p className="subheading">{"Area charts are typically used to visualize how a particular metric (such as % returns from a stock) performed compared to a certain baseline. An area chart is essentially a line chart where the area between the line and the zero-axis baseline is filled in. As such, area charts need to contain the y = 0 axis and can often dip below it."}</p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row">
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                    <img src={'/design/chart-stacked-area.png'} className="full-width" />
                    <h4>{'Stacked Area Chart'}</h4>
                    <p className="subheading">{"A stacked area chart is just like a line chart, except that the region below the plot is all colored. This is typically used when you want to describe how a quantity or volume (rather than something like price) changed over time. Or you have many data points. For very few data points, consider plotting a bar chart. Also, if you want to show the contribution from individual components."}</p>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                  <img src={'/design/chart-stacked-area-100.png'} className="full-width" />
                  <h4>{'100% Stacked Area Chart'}</h4>
                  <p className="subheading">{"This is almost the same as a stacked area chart, with a focus on relative differences rather than absolute ones. This should typically be used when you want to describe how a quantity or volume (rather than something like price) changed over time. "}</p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row">
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                    <img src={'/design/chart-scatter-plot.png'} className="full-width" />
                    <h4>{'Scatter Plot Chart'}</h4>
                    <p className="subheading">{"The default choice for plotting 2 arbitrary continuous features against one another. Add more continuous features through colour or point size, but prioritise clarity. A scatter plot can often be enhanced by overlaying a (linear or smoothing) fit to the 2 features."}</p>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                  <img src={'/design/chart-bubble.png'} className="full-width" />
                  <h4>{'Bubble Chart'}</h4>
                  <p className="subheading">{"While scatterplot lets you compare the relationship between 2 continuous variables, bubble charts are more suitable if you have 4-Dimensional data where two of them are numeric (X and Y) and one other categorical (color) and another numeric variable (size)."}</p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row">
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                    <img src={'/design/chart-bar.png'} className="full-width" />
                    <h4>{'Bar Chart'}</h4>
                    <p className="subheading">{" Bar charts are the workhorse for comparing continuous measurements across a reasonable number of category levels. Bar charts should always start from y = 0. To better accommodate long category labels bar charts can be rotated by 90 degrees to produce horizontal bars. Add another categorical feature with few levels via dodged bars. Stacked bars should have only 2 or maximum 3 stacked levels. (Bar charts are practically the same as column charts)."}</p>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                  <img src={'/design/chart-data-table.png'} className="full-width" />
                  <h4>{'Data Table'}</h4>
                  <p className="subheading">{"A simple table can be the fastest way to convey information if there is no clear trend that could be visualised. Keep the number of rows and columns small. Values of interest can be highlighted in colour or bold face."}</p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
            <ScrollFadeIn>
              <div className="row">
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                    <img src={'/design/chart-heatmap.png'} className="full-width" />
                    <h4>{'Heatmap'}</h4>
                    <p className="subheading">{"A heatmap is essentially a grid spanned by the levels of 2 categorical features that has tiles of different colour. The colour is often mapped to an additional continuous feature, or to the count of the categorical feature combinations. An alternative chart is the count plot, where circles of difference size are plotted on the grid points instead of tiles of different colour."}</p>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-height small-padding">
                  <div className="small-padding shadow">
                  <img src={'/design/chart-pie.png'} className="full-width" />
                  <h4>{'Pie Chart'}</h4>
                  <p className="subheading">{"Seriously, please don’t. Pie charts are objectively bad since they depend on comparing angles instead of the much easier to compare lengths of bars. Pie charts are the Michael Bay of the chart world: unnecessarily flashy yet confusingly popular visuals hiding a lack of substance. In almost all cases a bar chart will do a much better job at conveying the same information."}</p>
                  </div>
                </div>
              </div>
            </ScrollFadeIn>
          </div>
        </div>
      </div>
    );
  }
}

function Picker({ subheading, onChange, style, defaultValue, children }) {
  return (
    <div style={style}>
      <h7 className="subheading uppercase bold-text" style={{display:"block", marginBottom:'10px'}}>{subheading}</h7>
      <select
        defaultValue={defaultValue ? defaultValue.toString() : null}
        onChange={(e) => onChange(e)}
        style={{ marginRight: '20px', marginBottom:'20px' }}
      >
        {children}
      </select>
    </div>
  );
}
