import React, { Component } from 'react';
import './logoSection.css';
import MaterialIcon from 'material-icons-react';
import {Colors} from './../../utils/constants';
import DesignSection from './designSection';

const improperLogoNumbers = [1, 2, 3, 4, 5, 6, 7, 8];

export default class LogoSection extends Component {
  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'Our Logo'}>
          <div style={{'maxWidth':1500}}>
            <div className="medium-bottom-margin shadow">
              <div className="row middle centered-text">
                <div
                  className="small-12 medium-6 full-width centered-text primary-background" style={{flexWrap:'wrap',display:'flex', alignItems:'center', justifyContent:'center', height:'50vh'}}>
                  <img src={'/images/logo-light.png'} className="three-quarter-width" />
                </div>
                <div
                  className="small-12 medium-6 full-width centered-text" style={{flexWrap:'wrap',display:'flex', alignItems:'center', justifyContent:'center', height:'50vh'}}>
                  <img src={'/images/logo-dark.png'} className="three-quarter-width" />
                </div>
              </div>
              <div className="row middle centered-text">
                <div
                  className="small-12 medium-6 full-width centered-text primary-background">
                  <div className="small-padding full-width" style={{alignItems:'flex-end'}}>
                    <a href="/design/yipitdata-logo-white.zip" download="yipitdata-logo-white.zip">
                      <div className="button white-background primary-color">{'Download'}</div>
                    </a>
                  </div>
                </div>
                <div
                  className="small-12 medium-6 full-width centered-text">
                  <div className="small-padding full-width" style={{alignItems:'flex-end'}}>
                    <a href="/design/yipitdata-logo-dark.zip" download="yipitdata-logo-dark.zip">
                      <div className="button primary-background white">{'Download'}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesignSection>
      </div>
    );
  }
}
