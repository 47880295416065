export const Colors = {
  yipitBrandBlue:{
    cmyk: '93, 59, 51, 37',
    pantone: '316 C',
    hex: '#0D4753',
    name: 'Brand Blue',
  },
  yipitBrandLightBlue:{
    cmyk: '32, 7, 12, 0',
    pantone: '552 C',
    hex: '#ABD0D9',
    name: 'Light Blue',
  },
  yipitBrandAccent:{
    cmyk: '0, 54, 68, 0',
    pantone: '163 C',
    hex: '#F48D5C',
    name: 'Accent Color',
  },
  yipitBrandDarkGrey:{
    cmyk: '67, 60, 59, 44',
    pantone: '446 C',
    hex: '#444444',
    name: 'Dark Grey',
  },
  yipitBrandLightGrey:{
    cmyk: '43, 35, 35, 1',
    pantone: 'Cool Gray 7 C',
    hex: '#999999',
    name: 'Light Grey',
  },
  productBlue: {
    cmyk: '82, 35, 18, 0',
    pantone: '7704 C',
    hex: '#1788B0',
    name: 'Blue Package',
  },
  productRed: {
    cmyk: '3, 83, 69, 0',
    pantone: '178 C',
    hex: '#E9514E',
    name: 'Red Package',
  },
  productGreen: {
    cmyk: '81, 24, 67, 7',
    pantone: '327 C',
    hex: '#288B6F',
    name: 'Green Package',
  },
  productYellow: {
    cmyk: '4, 27, 100, 0',
    pantone: '7408 C',
    hex: '#F4BA01',
    name: 'Yellow Package',
  },
  bluePalette: {
    hex: '#0D4753',
    name: 'Blue',
    steps: [
      '#dcf5f9',
      '#b8eaf4',
      '#95e0ee',
      '#72d5e9',
      '#2bc0de',
      '#1ea7c2',
      '#19889f',
      '#136a7c',
      '#0D4753',
    ],
  },
  orangePalette: {
    hex: '#F48D5C',
    name: 'Orange',
    steps: [
      '#FAC9B3',
      '#F8BCA0',
      '#F7AE8D',
      '#F6A179',
      '#F48D5C',
      '#F27840',
      '#EF5E1A',
      '#D24C0F',
      '#AC3F0C',
    ],
  },
  greenPalette: {
    hex: '#288b6f',
    name: 'Green',
    steps: [
      '#A0E3D0',
      '#81DAC1',
      '#61D1B1',
      '#41C8A2',
      '#32AE8B',
      '#288B6F',
      '#206F58',
      '#174F3F',
      '#0E2F26',
    ],
  },
  yellowPalette: {
    hex: '#f4ba01',
    name: 'Yellow',
    steps: [
      '#FEDD71',
      '#FED448',
      '#FECA20',
      '#F4BA01',
      '#CB9C01',
      '#A27D01',
      '#7A5E01',
      '#513E00',
      '#291F00',
    ],
  },
  redPalette: {
    hex: '#E9514E',
    name: 'Red',
    steps: [
      '#F8C9C9',
      '#F4A6A4',
      '#EF8280',
      '#E9514E',
      '#E63A37',
      '#DA1E1B',
      '#B61916',
      '#911412',
      '#6D0F0D',
    ],
  },
  tealPalette: {
    hex: '#1788b0',
    name: 'Teal',
    steps: [
      '#81D1EF',
      '#5DC4EA',
      '#38B7E5',
      '#1CA6D9',
      '#1788B0',
      '#136F90',
      '#0E536C',
      '#093748',
      '#051C24',
    ],
  },
  black: {
    hex: '#8B8B8B',
    name: 'Black',
    steps: [
      '#2E2E2E',
      '#454545',
      '#5C5C5C',
      '#737373',
      '#8B8B8B',
      '#A2A2A2',
      '#B9B9B9',
      '#D0D0D0',
      '#E7E7E7',
      '#E7E7E7',
    ],
  },
  white: {
    hex: '#fff',
    name: 'white',
    steps: []
  },
};

export const BrandColors = [
  Colors.yipitBrandBlue,
  Colors.yipitBrandLightBlue,
  Colors.yipitBrandAccent,
  Colors.yipitBrandDarkGrey,
  Colors.yipitBrandLightGrey,
  ];
export const ProductColors = [
  Colors.productBlue,
  Colors.productRed,
  Colors.productGreen,
  Colors.productYellow
];
export const CategoricalColors = [
  Colors.bluePalette,
  Colors.orangePalette,
  Colors.greenPalette,
  Colors.yellowPalette,
  Colors.redPalette,
  Colors.tealPalette
];
export const generateSequentialPalette = (color, steps) => {
  switch (steps) {
    case 1:
      return [color.hex];
    case 2:
      return [
        color.steps[3],
        color.steps[7],
      ];
    case 3:
      return [
        color.steps[0],
        color.steps[4],
        color.steps[8],
      ];
    case 4:
      return [
        color.steps[2],
        color.steps[4],
        color.steps[6],
        color.steps[8],
      ];
    case 5:
      return [
        color.steps[0],
        color.steps[2],
        color.steps[4],
        color.steps[6],
        color.steps[8],
      ];
    case 6:
      return [
        color.steps[3],
        color.steps[4],
        color.steps[5],
        color.steps[6],
        color.steps[7],
        color.steps[8],
      ];
    case 7:
      return [
        color.steps[2],
        color.steps[3],
        color.steps[4],
        color.steps[5],
        color.steps[6],
        color.steps[7],
        color.steps[8],
      ];
    case 8:
      return [
        color.steps[1],
        color.steps[2],
        color.steps[3],
        color.steps[4],
        color.steps[5],
        color.steps[6],
        color.steps[7],
        color.steps[8],
      ];
    case 9:
      return color.steps;
    default:
      return [color.steps[4]];
  }
};
export const generateDivergingPalette = (color1, color2, steps) => {
  switch (steps) {
    case 1:
      return [color1.steps[4]];
    case 2:
      return [
        color1.steps[4],
        color2.steps[4]
      ];
    case 3:
      return [
        color1.steps[4],
        Colors['black'].steps[8],
        color2.steps[4]
      ];
    case 4:
      return [
        color1.steps[4],
        color1.steps[6],
        color2.steps[6],
        color2.steps[4]
      ];
    case 5:
      return [
        color1.steps[4],
        color1.steps[6],
        Colors['black'].steps[8],
        color2.steps[6],
        color2.steps[4]
      ];
    case 6:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
      ];
    case 7:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        Colors['black'].steps[8],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
      ];
    case 8:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
      ];
    case 9:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        Colors['black'].steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
      ];
    case 10:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
      ];
    case 11:
      return [
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        Colors['black'].steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4]
      ];
    case 12:
      return [
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3]
      ];
    case 13:
      return [
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        Colors['black'].steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3]
      ];
    case 14:
      return [
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2]
      ];
    case 15:
      return [
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        Colors['black'].steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2]
      ];
    case 16:
      return [
        color1.steps[1],
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2],
        color2.steps[1]
      ];
    case 17:
      return [
        color1.steps[1],
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        Colors['black'].steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2],
        color2.steps[1]
      ];
    case 18:
      return [
        color1.steps[0],
        color1.steps[1],
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        color2.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2],
        color2.steps[1],
        color2.steps[0]
      ];
    case 19:
      return [
        color1.steps[0],
        color1.steps[1],
        color1.steps[2],
        color1.steps[3],
        color1.steps[4],
        color1.steps[5],
        color1.steps[6],
        color1.steps[7],
        color1.steps[8],
        Colors['black'].steps[8],
        color1.steps[8],
        color2.steps[7],
        color2.steps[6],
        color2.steps[5],
        color2.steps[4],
        color2.steps[3],
        color2.steps[2],
        color2.steps[1],
        color2.steps[0]
      ];
    default:
      return [color1.steps[0]];
  }
};
