import React, { Component } from 'react';
import {Link, Redirect,useParams,withRouter} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {GetWebsiteCustomJob,DeleteDbItem, UpdateDbItem} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';
import ParseEditableInput from './../../components/adminFormFields/parseEditableInput';
import ParseEditableBoolean from './../../components/adminFormFields/parseEditableBoolean';
import ParseEditableTextarea from './../../components/adminFormFields/parseEditableTextarea';
import ParseRelationalArray from './../../components/adminFormFields/parseRelationalArray';
import ParseEditableImage from './../../components/adminFormFields/parseEditableImage/';

export class AdminWebsiteEditCustomJob extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let { id } = this.props.match.params;
    let item = await GetWebsiteCustomJob(id);
    this.fetchDepartments()
    this.setState({
      item:item
    })
  }
  delete = async() =>{
    var response = window.confirm("Are you sure you want to delete!");
    if (response == true) {
      await DeleteDbItem(this.state.item.id,'WebsiteCustomJobs')
      this.props.history.goBack()
    } else {
      return false
    }
  }

  fetchDepartments = async() =>{
    let response = await fetch('https://boards-api.greenhouse.io/v1/boards/yipitdata/jobs?content=true');
  	  let jobs = {};
      let departments = [];
      let departmentsDeduper = [];
      let locationDeduper = [];
      let locations = [];
  	  if(response){
  	    jobs = await response.json();
  	    if(jobs){
  	      jobs = jobs.jobs;
  	    }
  	  }
      console.log(jobs)
      for (let i = 0; i < jobs.length; i++) {
        let job = {
          departmentId:jobs[i].departments[0].id,
          departmentName:jobs[i].departments[0].name,
          location:jobs[i].location ? jobs[i].location.name : ''
        }
        if(departmentsDeduper.indexOf(job.departmentId) == -1){
          departments.push({
            id:job.departmentId,
            name:job.departmentName
          })
          departmentsDeduper.push(job.departmentId)
        }
        if(locationDeduper.indexOf(job.location) == -1){
          locations.push({
            name: job.location
          })
          locationDeduper.push(job.location)
        }
      }
  	  this.setState({
        departments:departments,
        locations:locations
      })
  }

  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <h2 className="white" style={{marginBottom:'0px'}}>{'Edit Job'}</h2>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="max-width-container" style={{marginLeft:0}}>
            {this.state.item &&
              <div>
                <div className="small-top-margin">
                  <h5>{'Job Name'}</h5>
                  <ParseEditableInput
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="name"
                    parsevalue={this.state.item.attributes.name}
                    placeholder="Name of company" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Job Location Name'}</h5>
                  <ParseEditableInput
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="locationName"
                    parsevalue={this.state.item.attributes.locationName}
                    placeholder="enter job location" />
                </div>
                <div className="small-top-margin">
                  <p><i>{'These are what locations are in greenhouse'}</i></p>
                  {this.state.locations && this.state.locations.map((data) =>{
                    return(
                      <div className="row">
                        <div className="small-6">
                          <p><b>{'Name: '}</b>
                            <span className="subheading">{data.name}</span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="small-top-margin">
                  <h5>{'Job Department Name'}</h5>
                  <ParseEditableInput
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="departmentName"
                    parsevalue={this.state.item.attributes.departmentName}
                    placeholder="department of job" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Job Department Code'}</h5>
                  <ParseEditableInput
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="departmentId"
                    parsevalue={this.state.item.attributes.departmentId}
                    placeholder="department of job" />
                </div>
                <div className="small-top-margin">
                  <p><i>{'These are what departments are in greenhouse'}</i></p>
                  {this.state.departments && this.state.departments.map((data) =>{
                    return(
                      <div className="row">
                        <div className="small-6">
                          <p><b>{'Name: '}</b>
                            <span className="subheading">{data.name}</span>
                          </p>
                        </div>
                        <div className="small-6">
                          <p><b>{'Id: '}</b>
                            <span className="subheading">{data.id}</span>
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <div className="small-top-margin">
                  <h5>{'Description'}</h5>
                  <ParseEditableTextarea
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="description"
                    parsevalue={this.state.item.attributes.description}
                    placeholder="job description" />
                </div>
                <div className="small-top-margin">
                  <h5>{'Status'}</h5>
                  <ParseEditableBoolean
                    parseclass="WebsiteCustomJobs"
                    parseobjectid={this.state.item.id}
                    parsekey="status"
                    parsevalue={this.state.item.attributes.status}
                   />
                </div>
                <div className="medium-top-margin">
                  <h5>{'Danger Zone'}</h5>
                  <div onClick={this.delete} className="button red-background white">{'Delete'}</div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AdminWebsiteEditCustomJob)
