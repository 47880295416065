import React, { Component } from 'react';
import Parse from 'parse';
import {GetUrlVariable} from './../../utils/functions';
import ParseEditableInput from './../../components/parseEditableFormElements/input';

export default class TitleSection extends Component {
  constructor(props) {
      super(props);
      this.state = {};
  }

  deleteSection = async() => {
    var posts = Parse.Object.extend("YipitReportSections");
    var query = new Parse.Query(posts);
    let post = await query.get(this.state.id)
    post.set("deleted",true);
    let blogPost = await post.save();
    this.setState({
      item:blogPost
    })
  }

  componentDidMount = () =>{
    this.initScreen();
  }
  initScreen = () =>{
    let item = this.props.item;
    let image = item.attributes.image;
    this.setState({
      id:item.id,
      item:item
    })
  }
  render() {
    let item = this.state.item;
    if(!item) return null;
    let editable = this.props.editable;
    return (
      <div className="report-title-section section-wrapper white-background">
      <ParseEditableInput
        parseclass="YipitReportSections"
        parseobjectid={this.state.item.id}
        parsekey="title"
        parsevalue={this.state.item.attributes.title}
        placeholder="" />
      </div>
    );
  }
}
