import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {ListCoverageSummaryPackages, ListCoverageSummaryProducts, ListCoverageSummaryCompanies} from './../../utils/dbFunctions';
import {TruncateDescription,ReturnProductColor} from './../../utils/functions';
import {Colors} from './../../utils/constants';
import MaterialIcon from 'material-icons-react';
import PortalModal from './../../components/portalModal';
import CompanyModal from './../../components/modals/companyModal';
import PackageModal from './../../components/modals/packageModal';
import ProductModal from './../../components/modals/productModal';
import {Helmet} from "react-helmet";

export default class CoverageSummary extends Component {
  constructor(props) {
      super(props);
      this.state={
        gridViewActive:true,
        items:[],
        updateKey:100,
        productToggleActive:true
      };
  }
  componentDidMount = async() =>{
    let packages = await ListCoverageSummaryPackages();
    let products = await ListCoverageSummaryProducts();
    let companies = await ListCoverageSummaryCompanies();

    products = products.sort((a, b) =>{
      let aName = a.attributes.name
      let bName = b.attributes.name
      if(aName < bName) { return -1; }
      if(aName > bName) { return 1; }
      return 0;
    })

    companies = companies.sort((a, b) =>{
      let aName = a.attributes.name;
      let bName = b.attributes.name;

      if(aName < bName) { return -1; }
      if(aName > bName) { return 1; }
      return 0;
    })

    this.setState({
      products:products,
      allProducts:products,
      allPackages:packages,
      allCompanies:companies,
      packages:packages,
      companies:companies
    })
  }

  activatePackageModal = (pkg) =>{
    this.resetModals()
    this.setState({
      packageModalActive:true,
      packageModalId:pkg.id,
      packageModalContent:pkg
    })
  }
  hidePackageModal = () =>{
    this.setState({
      packageModalActive:false,
      packageModalId:null,
      packageModalContent:null
    })
  }

  activateCompanyModal = (company) =>{
    this.resetModals()
    this.setState({
      companyModalActive:true,
      companyModalId:company.id,
      companyModalContent:company
    })
  }
  hideCompanyModal = () =>{
    this.setState({
      companyModalActive:false,
      companyModalId:null,
      companyModalContent:null
    })
  }
  activateProductModal = (product) =>{
    this.resetModals()
    this.setState({
      productModalActive:true,
      productModalId:product.id,
      productModalContent:product
    })
  }
  hideProductModal = () =>{
    this.setState({
      productModalActive:false,
      productModalId:null,
      productModalContent:null
    })
  }
  resetModals = () =>{
    this.setState({
      productModalActive:false,
      companyModalActive:false,
      packageModalActive:false
    })
  }

  toggleProducts = () =>{
    this.setState({
      productToggleActive:true,
      companyToggleActive:false,
      updateKey:this.state.updateKey+1
    })
  }

  toggleCompanies = () =>{
    this.setState({
      productToggleActive:false,
      companyToggleActive:true,
      updateKey:this.state.updateKey+1
    })
  }

  render() {
    let toggleClass = this.state.gridViewActive ? 'grid-view-active' : 'list-view-active'
    return (
      <div className="full-height full-width">

        <Helmet>
          <title>YipitData — Coverage Summary</title>
        </Helmet>
        <div className="hero-padding primary-background centered-text design-site-mobile-hero-padding" style={{backgroundImage:'url(/images/triangle-texture.png)'}}>
          <h3 className="white" style={{marginTop:0, marginBottom:0, paddingTop:50, paddingBottom:100}}>{'Coverage Summary'}</h3>
        </div>
        <div className="lip">
          <div className="medium-padding" style={{paddingTop:0, paddingBottom:0}}>
            <div className="package-row-container row max-width-container mobile-overflow-scroll">
              {this.state.packages && this.state.packages.map((item) =>{
                return(
                  <div className="package-row small-12 medium-3 large-3 tiny-padding full-height hover-zoom">
                    <div onClick={()=>this.activatePackageModal(item)} className="shadow full-height white-background" style={{cursor:'pointer'}}>
                      <div className="shadow relative">
                        <div style={{height:20, width:'100%', backgroundColor:item.attributes.color}}></div>
                        <div className="centered-text" style={{padding:5}}>
                          {item.attributes.name &&
                            <h6>{item.attributes.name}</h6>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div className="row max-width-container tiny-padding" style={{paddingTop:40}}>
          <div className="small-12 medium-12 row" style={{justifyContent:'center'}}>
            <div
              onClick={this.toggleProducts}
              className={this.state.productToggleActive ? 'product-toggle active' : 'product-toggle'}>
              {'Our Products'}
            </div>
            <div
              onClick={this.toggleCompanies}
              className={this.state.companyToggleActive ? 'company-toggle active' : 'company-toggle'}>
              {'Companies We Cover'}
            </div>
          </div>
        </div>
        <div className="small-padding left-text" style={{paddingTop:0}}>
          {this.state.gridViewActive &&
            <div className="max-width-container tiny-padding">
              {this.state.productToggleActive &&
                <div className="row full-width">
                  {this.state.products && this.state.products.map((item) =>{
                    let color = '#999';
                    let targetGroups = this.state.groups && this.state.groups.filter((group) => group.attributes.products && group.attributes.products.indexOf(item.id) != -1);
                    let targetCompanies = this.state.companies && this.state.companies.filter((company) => item.attributes.companies && item.attributes.companies.indexOf(company.id) != -1);
                    let targetBundleGroups = null;
                    color = ReturnProductColor(item, this.state.packages);
                    let windowSize = 'medium';
                    if(window.innerWidth > 1400){
                      windowSize = 'large';
                    }
                    return(
                      <div className="small-12 medium-3 large-2 grid-view-tile"  style={{cursor:'pointer'}} onClick={()=>this.activateProductModal(item)}>
                        <div className="shadow full-height" style={{position:'relative'}}>
                          <div className="row">
                            <div style={{flex:1}}>
                              <b style={{fontSize:18}}>{item.attributes.name}</b>
                              <div className="left-text">
                                <span>
                                  <p className="subheading" style={{fontSize:11}}>
                                    {targetCompanies && targetCompanies.map((item, index) =>{
                                      return(
                                        <span>
                                          {item.attributes.symbol ?
                                              <span>{item.attributes.symbol}{index != targetCompanies.length -1 && ', '}</span>
                                            :
                                              <span>{item.attributes.name}{index != targetCompanies.length -1 && ', '}</span>
                                          }
                                        </span>
                                      )
                                    })}
                                  </p>
                                </span>
                              </div>
                            </div>
                            <div style={{width:30, display:'none', alignItems:'center', justifyContent:'center'}}>
                              <div style={{height:10, width:10, borderRadius:'50%', backgroundColor:color}} />
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
              {this.state.companyToggleActive &&
                <div className="row full-width">
                  {this.state.companies && this.state.companies.map((item) =>{
                    let windowSize = 'medium';
                    if(window.innerWidth > 1400){
                      windowSize = 'large';
                    }
                    return(
                      <div className="small-12 medium-3 large-2 grid-view-tile"  style={{cursor:'pointer'}} onClick={()=>this.activateCompanyModal(item)}>
                        <div className="shadow full-height" style={{position:'relative'}}>
                          <div style={{flex:1}}>
                            <b style={{fontSize:18}}>{item.attributes.name}</b><br />
                            <p className="subheading" style={{"fontSize":'11px'}}>
                              <span>
                                {item.attributes.symbol && <span>{item.attributes.symbol}</span>}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
            </div>
          }
          {this.state.companyModalActive &&
            <PortalModal
              modalHidden={this.hideCompanyModal}
              modalActive={this.state.companyModalActive}
              content={
                <CompanyModal
                onPackageClick={this.activatePackageModal}
                onProductClick={this.activateProductModal}
                packages={this.state.allPackages}
                products={this.state.allProducts}
                company={this.state.companyModalContent}
                companyId={this.state.companyModalId} />
              }
            />
          }
          {this.state.productModalActive &&
            <PortalModal
              modalHidden={this.hideProductModal}
              modalActive={this.state.productModalActive}
              content={
                <ProductModal
                onPackageClick={this.activatePackageModal}
                onCompanyClick={this.activateCompanyModal}
                companies={this.state.allCompanies}
                packages={this.state.allPackages}
                product={this.state.productModalContent}
                productId={this.state.productModalId} />
              }
            />
          }
          {this.state.packageModalActive &&
            <PortalModal
              modalHidden={this.hidePackageModal}
              modalActive={this.state.packageModalActive}
              content={
                <PackageModal
                  onProductClick={this.activateProductModal}
                  onCompanyClick={this.activateCompanyModal}
                  comapanies={this.state.allCompanies}
                  products={this.state.allProducts}
                  pkg={this.state.packageModalContent}
                  packageId={this.state.packageModalId}
                />
              }
            />
          }
        </div>
      </div>
    )
  }
}
