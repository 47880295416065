import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {ListCoverageSummaryPackages, ListCoverageSummaryProducts, ListCoverageSummaryCompanies} from './../../utils/dbFunctions';
import {TruncateDescription,ReturnProductColor} from './../../utils/functions';
import {Colors} from './../../utils/constants';
import MaterialIcon from 'material-icons-react';
import PortalModal from './../../components/portalModal';
import CompanyModal from './../../components/modals/companyModal';
import PackageModal from './../../components/modals/packageModal';
import ProductModal from './../../components/modals/productModal';
import {Helmet} from "react-helmet";

export default class CoverageSummaryPrintLarge extends Component {
  constructor(props) {
      super(props);
      this.state={
        gridViewActive:true,
        items:[],
        updateKey:100,
        productToggleActive:true
      };
  }
  componentDidMount = async() =>{
    let packages = await ListCoverageSummaryPackages();
    let products = await ListCoverageSummaryProducts();
    let companies = await ListCoverageSummaryCompanies();
    let packageArray = [];
    for (let i = 0; i < packages.length; i++) {
      let pkg = packages[i];
      let packageId = pkg.id;

      let packageProducts = products.filter((product) => pkg.attributes.products && pkg.attributes.products.indexOf(product.id) != -1);
      let packageCompanies = [];
      for (let i = 0; i < packageProducts.length; i++) {
        if(packageProducts[i].attributes.companies){
          for (let x = 0; x < packageProducts[i].attributes.companies.length; x++) {
            if(packageCompanies.indexOf(packageProducts[i].attributes.companies[x].id) == -1){
              packageCompanies.push(packageProducts[i].attributes.companies[x])
            }
          }
        }
      }
      packageCompanies = companies.filter((company) => packageCompanies.indexOf(company.id) != -1);

      packageProducts = packageProducts.sort((a, b) =>{
          let aName = a.attributes.name
          let bName = b.attributes.name

          if(aName < bName) { return -1; }
          if(aName > bName) { return 1; }
          return 0;
      })

      packageCompanies = packageCompanies.sort((a, b) =>{
          let aName = a.attributes.symbol;
          let bName = b.attributes.symbol;
          if(!aName){
            aName = a.attributes.name
          }
          if(!bName){
            bName = b.attributes.name
          }
          if(a.attributes.name)
          if(aName < bName) { return -1; }
          if(aName > bName) { return 1; }
          return 0;
      })
      packageArray.push({
        package:pkg,
        packageProducts:packageProducts,
        packageCompanies:packageCompanies
      })
    }
    this.setState({
      packageArray:packageArray,
      companies:companies
    })
  }

  toggleProducts = () =>{
    this.setState({
      productToggleActive:true,
      companyToggleActive:false,
      updateKey:this.state.updateKey+1
    })
  }

  toggleCompanies = () =>{
    this.setState({
      productToggleActive:false,
      companyToggleActive:true,
      updateKey:this.state.updateKey+1
    })
  }

  render() {
    let toggleClass = this.state.gridViewActive ? 'grid-view-active' : 'list-view-active';

    return (
      <div className="full-height full-width">
        <Helmet>
          <title>YipitData — Coverage Summary</title>
        </Helmet>
        <div className="printable-coverage-summary" style={{marginTop:-20}}>
          <div className="left-text">
            <div className="full-width">
              <div style={{minHeight:'100vh'}}>
                {this.state.packageArray && this.state.packageArray.map((item) =>{
                  let pkg = item.package;
                  let packageProducts = item.packageProducts;
                  return(
                    <div className="small-12 medium-12">
                      <h6>
                        <span>
                          {pkg.attributes.name && <b style={{color:pkg.attributes.color}}>{pkg.attributes.name}</b>}
                        </span>
                      </h6>
                      <div className="row">
                        {packageProducts && packageProducts.map((item) =>{
                          let targetCompanies = this.state.companies && this.state.companies.filter((company) => item.attributes.companies && item.attributes.companies.indexOf(company.id) != -1);
                          return(
                            <div className="small-12 medium-6 row">
                              <p style={{fontSize:14, lineHeight:1.75}}>
                                <span>
                                  {item.attributes.name && <b>{item.attributes.name}</b>}
                                </span>
                              </p>
                              <div className="row bundle-included-tag" style={{alignItems:'center', marginLeft:'8px'}}>
                                <p className="subheading" style={{fontSize:14, lineHeight:1.75}}>
                                {targetCompanies && targetCompanies.map((item, index) =>{
                                  return(
                                    <span>
                                      {item.attributes.symbol ?
                                        <span>{item.attributes.symbol}</span>
                                        :
                                        <span>{item.attributes.name}</span>
                                      }
                                      {index != targetCompanies.length -1 && ', '}
                                    </span>
                                  )
                                })}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })}
              </div>
              <div className="page-break"></div>
              {this.state.packageArray && this.state.packageArray.map((item) =>{
                let pkg = item.package;
                let packageProducts = item.packageProducts;
                let half = Math.ceil(packageProducts.length / 2);
                let firstHalf = packageProducts;
                let secondHalf;
                if(packageProducts.length > 2){
                  firstHalf = packageProducts.slice(0, half);
                  secondHalf = packageProducts.slice(-half);
                  if(pkg.attributes.name == "Red Package"){
                    firstHalf = packageProducts.slice(0, half+2);
                    secondHalf = packageProducts.slice(-half+2);
                  }
                }

                return(
                  <div className="small-12 medium-12">
                    <h5 className="tiny-padding" style={{backgroundColor:pkg.attributes.color}}>
                      <span>
                        {pkg.attributes.name && <b style={{color:'#fff'}}>{pkg.attributes.name}</b>}
                      </span>
                    </h5>
                    <div className="row">
                      <div className="small-12 medium-6">
                        {firstHalf && firstHalf.map((item,index) =>{
                          let targetCompanies = this.state.companies && this.state.companies.filter((company) => item.attributes.companies && item.attributes.companies.indexOf(company.id) != -1);
                          return(
                            <div className="prod-meta">
                              <div className="row" style={{ padding:10, paddingBottom:0}}>
                                <p style={{fontSize:14, lineHeight:1.75}}>
                                  <span>
                                    {item.attributes.name && <b>{item.attributes.name}</b>}
                                  </span>
                                </p>
                                <div className="row bundle-included-tag" style={{alignItems:'center', marginLeft:'8px'}}>
                                  <p className="subheading" style={{fontSize:14, lineHeight:1.75}}>
                                    {targetCompanies && targetCompanies.map((item, index) =>{
                                      return(
                                        <span>
                                          {item.attributes.symbol ?
                                            <span>{item.attributes.symbol}</span>
                                            :
                                            <span>{item.attributes.name}</span>
                                          }
                                          {index != targetCompanies.length -1 && ', '}
                                        </span>
                                      )
                                    })}
                                  </p>
                                </div>
                              </div>
                              <p className="subheading preserve-space" style={{fontSize:13, lineHeight:1.75, padding:10, paddingTop:0}}>
                                {item.attributes.description && <span>{item.attributes.description}</span>}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                      <div className="small-12 medium-6">
                        {secondHalf && secondHalf.map((item,index) =>{
                          let targetCompanies = this.state.companies && this.state.companies.filter((company) => item.attributes.companies && item.attributes.companies.indexOf(company.id) != -1);
                          return(
                            <div className="prod-meta">
                              <div className="row" style={{ padding:10, paddingBottom:0}}>
                                <p style={{fontSize:14, lineHeight:1.75}}>
                                  <span>
                                    {item.attributes.name && <b>{item.attributes.name}</b>}
                                  </span>
                                </p>
                                <div className="row bundle-included-tag" style={{alignItems:'center', marginLeft:'8px'}}>
                                  <p className="subheading" style={{fontSize:14, lineHeight:1.75}}>
                                  {targetCompanies && targetCompanies.map((item, index) =>{
                                    return(
                                      <span>
                                        {item.attributes.symbol ?
                                          <span>{item.attributes.symbol}</span>
                                          :
                                          <span>{item.attributes.name}</span>
                                        }
                                        {index != targetCompanies.length -1 && ', '}
                                      </span>
                                    )
                                  })}
                                  </p>
                                </div>
                              </div>
                              <p className="subheading preserve-space" style={{fontSize:13, lineHeight:1.75, padding:10, paddingTop:0}}>
                                {item.attributes.description && <span>{item.attributes.description}</span>}
                              </p>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
    </div>
    </div>
    )
  }
}
