import React, { Component } from 'react';
import {GetUrlVariable} from './../../utils/functions';
import {Products} from './../../utils/constants';
import TextOverImageBlock from './../../components/textOverImageBlock';
import ParseEditableInput from './../../components/parseEditableFormElements/input';
import ParseEditableArray from './../../components/parseEditableFormElements/array';
import ParseEditableTextarea from './../../components/parseEditableFormElements/textArea';
import RelationalArray from './../../components/parseEditableFormElements/textArea';
import Axios from 'axios';
import Parse from 'parse';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import {withRouter,Redirect} from "react-router-dom";

class AdminReportAdd extends Component {
  constructor(props) {
      super(props);
      this.state={
        tags:[]
      };
  }
  titleChange = (e) =>{
    let value = e.target.value;
    this.setState({
      title:value
    })
  }

  onBack = () =>{
    this.props.history.goBack()
  }
  onSave = async() =>{
    const Blogs = Parse.Object.extend('YipitReports');
    let date = new Date();
    let blog = new Blogs();
    blog.set("title",this.state.title);
    blog.set("date",date);
    let blogPost = await blog.save();
    window.location.assign('/')
  }
  render() {
    return (
      <div className="large-padding light-grey-background">
        <div className="max-width-container white-background tiny-padding shadow">
          <div className="full-width">
            <h6 className="full-width" style={{marginTop:"20px"}}>{'Title'}</h6>
            <input
              className="full-width tiny-padding light-grey-background"
              value={this.state.title}
              onChange={this.titleChange}
              placeholder="Enter a Title" />
          </div>
          <div style={{marginTop:"20px"}}>
            <div onClick={this.onBack}  style={{margin:"10px"}} className="button light-grey-background">{'cancel'}</div>
            <div onClick={this.onSave}  style={{margin:"10px"}} className="button primary-background white">{'Save'}</div>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(AdminReportAdd)
