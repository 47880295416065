import React, { Component } from 'react';
import './logoSection.css';
import DesignSection from './designSection';

export default class PowerpointSection extends Component {
  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'Slides'}>
          <div className="small-bottom-margin">
            <div className="small-padding no-top-padding">
              <div className="">
                <div className="row"
                  style={{justifyContent: 'center'}}>
                  <div
                    className="small-12 medium-10 centered-text full-height small-padding">
                    <img src={'/design/google-slides-library.png'} className="full-width small-bottom-margin" alt="Powerpoint library" />
                    <p className="subheading tiny-bottom-margin">{'To use this template, make a copy of the entire presentation. This is how your charts, tables, and title slides should look.'} </p>
                    <a className="downloadText" href="https://docs.google.com/presentation/u/0/?tgif=d&ftv=1" target="_blank">
                      Google Slides Library
                      <img className="relative" src="/images/download-icon.png" alt="View Google Slides library" style={{ height: '1.2em', margin:"auto", top: '4px', paddingLeft: '10px' }} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesignSection>
      </div>
    );
  }
}
