import React, { Component } from 'react';
import "./textOverImageBlock.css";
import { withController, Parallax } from 'react-scroll-parallax';

class TextOverImageBlock extends Component {
  constructor(props) {
      super(props);
  }
  componentDidMount = ()=>{
    this.props.parallaxController.update();
  }
  render() {
    let title = this.props.title;
    let description = this.props.description;
    let backgroundImage = this.props.backgroundImage;
    let buttonLink = this.props.buttonLink;
    let buttonTitle = this.props.buttonTitle;

    return (
      <div className="text-over-image-block">
        <div className="image-block-overlay large-padding">
          <Parallax y={[-10, 10]}>
            <div className="full-width">
                <h3>{title}</h3>
                <p>{description}</p>
                {buttonLink && <a className="button primary-background small-top-margin" style={{color:"#fff"}} href={buttonLink}>{buttonTitle}</a>}
            </div>
          </Parallax>
        </div>
        <div className="image-block-overlay-color" style={{"backgroundColor": "rgba(0,0,0,.35)"}}></div>
        <div className="image-block-background" style={{"backgroundImage": "url("+backgroundImage+")"}}></div>
      </div>
    );
  }
}

export default withController(TextOverImageBlock);
