import React, { Component } from 'react';
import Parse from 'parse';

export default class ParseEditableBoolean extends Component {
  constructor(props) {
      super(props);
      var value = "false";
      if(this.props.parsevalue === true){
        value = "true";
      }
      this.state = ({
        options:this.props.options,
        value:value
      })
  }

  onChange = async(e) => {
    var parseclass = this.props.parseclass;
    var parseobjectid = this.props.parseobjectid;
    var parsekey = this.props.parsekey;
    var value = e.target.value;
    this.setState({
      value:value
    })
    if(value === "true"){
      value = true
    } else {
      value = false
    }

    var Query = Parse.Object.extend(parseclass);
    var query = new Parse.Query(Query);
    let result = await query.get(parseobjectid)
    result.set(parsekey,value)
    result.save(null, {
      success: (result) => {
        if(this.props.callback){
          this.props.callback();
        }
      },
      error: (error) => {
        alert(error.message)
      }
    });
  }

  render() {

    return (
      <select
        className="full-width outline"
        value={this.state.value} onChange={this.onChange}>
        <option value="true">{"True"}</option>
        <option value="false">{"False"}</option>
      </select>
    );
  }
}
