import React, { Component } from 'react';
import {GetUrlVariable} from './../../utils/functions';
import {BlogOptions} from './../../utils/constants';
import ReportSectionTypeChooser from './../../components/reportSectionTypeChooser';
import ReportTitleSection from './../../components/reportTitleSection';
import ReportChartSection from './../../components/reportChartSection';
import ReportTextSection from './../../components/reportTextSection';
import ReportImageSection from './../../components/reportImageSection';
import TextOverImageBlock from './../../components/textOverImageBlock';
import ParseEditableInput from './../../components/parseEditableFormElements/input';
import ParseEditableArray from './../../components/parseEditableFormElements/array';
import ParseEditableTextarea from './../../components/parseEditableFormElements/textArea';
import ReportSectionEditableImage from './../../components/reportSectionEditableImage';
import RelationalArray from './../../components/parseEditableFormElements/textArea';
import Axios from 'axios';
import Parse from 'parse';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import "react-datepicker/dist/react-datepicker.css";
import {withRouter,Link,Redirect} from "react-router-dom";
import './adminReport.css';

class AdminReportEdit extends Component {
  constructor(props) {
      super(props);
      this.state={
        updateKey:0,
        tags:[]
      };
  }
  componentDidMount = () =>{
    this.initPage();
  }
  initPage = async() =>{
    let id = GetUrlVariable('id');
    var posts = Parse.Object.extend("YipitReports");
    var query = new Parse.Query(posts);
    let report = await query.get(id)
    var Sections = Parse.Object.extend("YipitReportSections");
    var sectionQuery = new Parse.Query(Sections);
    sectionQuery.equalTo('parent',id);
    sectionQuery.ascending('order');
    sectionQuery.notEqualTo('deleted',true);
    let sections = await sectionQuery.find();
    let updateKey = this.state.updateKey+1;
    this.setState({
      id:id,
      updateKey:updateKey,
      date:report.attributes.date,
      title:report.attributes.title,
      sections:sections,
      report:report
    })
  }
  titleChange = (e) =>{
    let value = e.target.value;
    this.setState({
      title:value
    })
  }
  dateChange = (e) =>{
    console.log(e)
    this.setState({
      date:e
    })
  }

  createNewSection = async() =>{
    let id = GetUrlVariable('id');
    let sectionLength = this.state.sections.length;
    const Sections = Parse.Object.extend('YipitReportSections');
    let section = new Sections();
    section.set("parent",id);
    section.set("order",sectionLength);
    let newSection = await section.save();
    this.initPage();
  }
  onBack = () =>{
    this.props.history.goBack()
  }
  onSave = async() =>{
    let id = GetUrlVariable('id');
    var posts = Parse.Object.extend("YipitReports");
    var query = new Parse.Query(posts);
    let post = await query.get(id)
    post.set("title",this.state.title);
    post.set("date",this.state.date);
    post.set("sections",this.state.sections);
    let blogPost = await post.save();
  }
  onDelete = async() =>{
    let id = GetUrlVariable('id');
    var posts = Parse.Object.extend("YipitReports");
    var query = new Parse.Query(posts);
    let post = await query.get(id)
    post.set("deleted",true);
    let blogPost = await post.save();
    this.props.history.goBack()
  }
  onPreview = ()=>{
    let id = GetUrlVariable('id');
    window.open("reportDetail?id="+id);
  }
  render() {
    return (
      <div key={this.state.updateKey} className="report-edit-page large-padding light-grey-background blog-editor" style={{paddingRight:"0px", paddingLeft:"0px"}}>
        <div>
          <Link to="/">
            <div style={{display:'flex', maxWidth:'200px', padding:"10px"}}>
              <img style={{height:30, width:30}} src="/images/back-black.png" />
              <div style={{marginTop:'5px', marginLeft:'7px'}} >{'Back'}</div>
            </div>
          </Link>
        </div>
        <div className="row">
          <div className="small-12 medium-8 tiny-padding" style={{paddingTop:"0px"}}>
            <div className={'full-width'}>
              {this.state.sections && this.state.sections.map((data, index) =>{
                let section = <ReportSectionTypeChooser item={data} onChange={this.initPage} index={index} />;
                if(data.attributes.type == "image"){
                  section = <ReportImageSection item={data} editable />
                }
                if(data.attributes.type == "text"){
                  section = <ReportTextSection item={data} editable />
                }
                if(data.attributes.type == "title"){
                  section = <ReportTitleSection item={data} />
                }
                if(data.attributes.type == "chart"){
                  section = <ReportChartSection item={data} />
                }
                return section;
              })}
              <div className="small-padding button primary-background white" onClick={this.createNewSection} style={{marginTop:"20px"}}>
                {'Add New Section'}
              </div>
            </div>
          </div>
          <div className="small-12 medium-4 white-background shadow-light tiny-padding">
            <div className="full-width">
              <h6 className="full-width" style={{marginTop:"20px"}}>{'Title'}</h6>
              <input
                className="full-width tiny-padding light-grey-background"
                value={this.state.title}
                onChange={this.titleChange}
                onBlur={this.onSave}
                placeholder="Enter a Title" />
            </div>
            <div className="full-width" style={{marginTop:"20px"}}>
              <h6 className="full-width">{'Date'}</h6>
              <DatePicker
                className="full-width"
                selected={this.state.date}
                onChange={date => this.dateChange(date)}
                onBlur={this.onSave}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={60}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <div style={{marginTop:"20px"}}>
              <Link to={"/reportDetail?id="+this.state.id} style={{marginTop:"5px"}}>
                <div style={{margin:"10px"}} className="button light-grey-background">{'Preview'}</div>
              </Link>
            </div>
            <div style={{marginTop:"20px"}}>
              <div onClick={this.onDelete}  style={{margin:"10px"}} className="button light-grey-background">{'Delete'}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(AdminReportEdit)
