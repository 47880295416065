import React, { Component } from 'react';
import Parse from 'parse';
import './parseEditableImage.css';

export default class ParseEditableImage extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = () =>{
    this.updateData();
  }
  updateData = async() =>{
    let id = this.props.parseobjectid;
    console.log(this.props.parseclass)
    let posts = Parse.Object.extend(this.props.parseclass);
    let query = new Parse.Query(posts);
    let result = await query.get(id);
    console.log(result)
    if(result){
      this.setState({
        result:result,
        uploading:false
      })
    }

  }

  onPhotoAdded = async() =>{
    let id = this.props.parseobjectid;
    this.setState({
      uploading:true
    })
    let addPhoto = document.getElementById(this.props.parseobjectid+"-"+this.props.parseclass+this.props.parsekey+"-photo");
    let parseFile = new Parse.File("photo.jpg", addPhoto.files[0]);
    let savedParseFile = await parseFile.save();
    console.log(savedParseFile)
    let SectionQuery = Parse.Object.extend(this.props.parseclass);
    let sectionQuery = new Parse.Query(SectionQuery);
    let result = await sectionQuery.get(id)
    console.log(result)
    if(savedParseFile){
      result.set(this.props.parsekey, savedParseFile);
    }
    let savedResult = await result.save();
    this.updateData();
  }

  deleteSection = async() =>{
    let id = this.props.parseobjectid;
    console.log(this.props.parseclass)
    let posts = Parse.Object.extend(this.props.parseclass);
    let query = new Parse.Query(posts);
    let result = await query.get(id);
    console.log(result)
    if(result){
      result.destroy()
      this.setState({
        deleted:true
      })
    }
  }

  uploadClicked = () =>{
    document.getElementById(this.props.parseobjectid+"-"+this.props.parseclass+this.props.parsekey+"-photo").click();
  }

  render() {
    console.log(this.state.result)
    if(this.state.deleted) return null;
    if(!this.state.result) return null;
    var resizeMode = "background-image-fill"
    if(this.props.resizeMode == "fit"){
      resizeMode = "background-image-fit"
    }
    var image = '/images/rectangle.png';
    var defaultImage = '/images/image-placeholder.png';
    if(this.state.result.attributes[this.props.parsekey]){
      image = this.state.result.attributes[this.props.parsekey].url()
    }
    if(this.state.uploading){
      defaultImage = '/images/loading.gif';
    }
    var imageTitle = 'Upload Image';
    if(this.state.imageTitle){
      defaultImage = imageTitle;
    }
    return (
      <div className="full-width image-placeholder-background image-upload-container" style={{backgroundImage:"url("+defaultImage+")"}}>
        {!this.props.noDeleteOption &&  <div onClick={this.deleteSection} className="image-delete-icon"><img className="full-width" src="/images/close-black.png" /></div>}
        {!this.state.uploading && this.state.result.attributes[this.props.parsekey] ?
          <img
            src={image}
            className={"full-width "+resizeMode}
          />
          :
          <img
            src={image}
            className={"full-width background-image-fit"}
          />
        }
        <div className="full-width image-upload-container-upload-button-container" onClick={this.uploadClicked}>
          <div className="button">{imageTitle}</div>
        </div>
        <input id={this.props.parseobjectid+"-"+this.props.parseclass+this.props.parsekey+"-photo"} className="hidden" style={{display:"none"}} type="file" name="cover" onChange={this.onPhotoAdded} />
      </div>
    );
  }
}
