import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Helmet} from "react-helmet";
import Parse from 'parse';
import { withController, Parallax } from 'react-scroll-parallax';
import {GetUrlVariable} from './../../utils/functions';
import PortalModal from './../../components/portalModal';

class BlogHome extends Component {
  constructor(props) {
      super(props);
      this.state ={};
  }
  componentDidMount = async() =>{
    let id = GetUrlVariable('id');
    let reports = Parse.Object.extend("YipitReports");
    let query = new Parse.Query(reports);
    let post = await query.get(id);
    var Sections = Parse.Object.extend("YipitReportSections");
    var sectionQuery = new Parse.Query(Sections);
    sectionQuery.equalTo('parent',id);
    sectionQuery.ascending('order');
    sectionQuery.notEqualTo('deleted',true);
    let sections = await sectionQuery.find();
    console.log(post)
    console.log(sections)
    this.setState({
      post:post,
      sections:sections
    })
  }

  showImage =(url) =>{
    this.setState({
      isOpen:true,
      fullScreenImage:url
    })
  }

  hideImage =() =>{
    this.setState({
      isOpen:false,
      fullScreenImage:null,
      onFullScreenImageLoaded:false
    })
  }

  onFullScreenImageLoad = () => {
    this.setState({
      onFullScreenImageLoaded:true
    })
  }

  render() {
    if(!this.state.post) return null;
    let fullScreenImageLoaded = "";
    if(this.state.onFullScreenImageLoaded){
      fullScreenImageLoaded ="loaded"
    }
    return (
      <div style={{maxWidth:1000}}>
        <div>{this.state.post.attributes.title}</div>
        <div>{this.state.post.attributes.date.toString()}</div>
        {this.state.sections && this.state.sections.map((data) =>{
          if(data.attributes.type == "image" && data.attributes.image){
            return <img className="full-width" src={data.attributes.image.url()} />
          } else if(data.attributes.type == "chart" && data.attributes.image){
            return (
              <div className="section-margin">
                <div>{data.attributes.exhibit ? 'Exhibit '+data.attributes.exhibit : ""}{data.attributes.title ? data.attributes.title : ""}</div>
                <img className="full-width" src={data.attributes.image.url()} />
                {data.attributes.note && <div>{data.attributes.note}</div>}
              </div>
            )
          } else if(data.attributes.type == "title" && data.attributes.title){
            return (
              <div>{data.attributes.title}</div>
            )
          } else{
            return <div><div dangerouslySetInnerHTML={{__html:data.attributes.content}} /></div>
          }
        })}
      </div>
    )
  }
}

export default withRouter(BlogHome)
