import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {ListWebsiteJobDepartments} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';
import ParseSortableList from './../../components/parseSortableList';

export default class AdminWebsiteJobDepartmentsList extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:null
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let items = await ListWebsiteJobDepartments();
    this.setState({
      items:items
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <div className="small-12 medium-10">
                <h2 className="white">{'Website Jobs Departments'}</h2>
                <p className="white subheading">{'Jobs Departments Greenhouse Mapping'}</p>
              </div>
              <div className="small-12 medium-2 row" style={{alignItems:'center'}}>
                <Link to={"/admin/department/add"}>
                  <div className="full-width button primary-color white-background">{'Add New'}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="max-width-container" style={{marginLeft:0}}>
            {this.state.items &&
              <ParseSortableList
                parselist={this.state.items}
                parseclass={'WebsiteJobDepartments'}
                onclickpath={'/admin/department/'}
                onclickkey={'id'}
                parselabelkey={'name'}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}
