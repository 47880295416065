import React, { Component } from 'react';
import Parse from 'parse';
import Autocomplete from "react-autocomplete";
import { Link } from "react-router-dom";

export default class ParseRelationalArray extends Component {
  constructor(props) {
      super(props);
      this.state = ({
        value:"",
        array:this.props.parsevalue ? this.props.parsevalue : [],
        values:[],
        displayValues:[]
      })
  }

  componentDidMount = async() => {
    var displayValues = [];
    var values = [];
    var Query = Parse.Object.extend(this.props.parsevaluesclass);
    var query = new Parse.Query(Query);
    query.limit(1000)
    let results = await query.find()
    console.log(results)
    for (var i = 0; i < results.length; i++) {
      values.push(JSON.parse(JSON.stringify(results[i])))
      if(this.state.array.indexOf(results[i].id) != -1){
        displayValues.push(JSON.parse(JSON.stringify(results[i])))

      };
      if(i == results.length -1){
        console.log('ready')
        console.log(values)
        this.setState({
          values:values,
          displayValues:displayValues
        })
      }
    }
  }

  addItem = (id) =>{
    var displayValues = this.state.displayValues;
    var values = this.state.values;
    var array = this.state.array;
    console.log(array)
    for (let i = 0; i < values.length; i++) {
      if (id == values[i].name){
        if(array.indexOf(values[i].objectId) == -1){
          displayValues.push(values[i])
          array.push(values[i].objectId)
        }
      }
    }
    this.setState({
      array:array,
      displayValues:displayValues,
      value:''
    },()=>{
      this.onSave()
    })
  }

  removeItem = (id) =>{
    var newDisplayValues = [];
    var displayValues = this.state.displayValues;
    var arr = [];

    for (var i = 0; i < displayValues.length; i++) {
      if (displayValues[i].name != id){
        newDisplayValues.push(displayValues[i])
        arr.push(displayValues[i].id)
      }
      if(i == displayValues.length -1){
        this.setState({
          array:arr,
          displayValues:newDisplayValues,
          value:''
        },()=>{
          this.onSave()
        })
      }
    }

  }

  matchValue = (item, val) =>{
    let value = val;
    if(val){
      value = val.toLowerCase()
    }
    return (
      item.name.toLowerCase().indexOf(value) !== -1
      ||
      item.symbol && item.symbol.toLowerCase().indexOf(value) !== -1
    )
  }

  onSave = async(e) => {
    var parseclass = this.props.parseclass;
    var parseobjectid = this.props.parseobjectid;
    var parsekey = this.props.parsekey;
    var array = this.state.array;
    let arrayToSave = [];
    for (let i = 0; i < this.state.displayValues.length; i++) {
      arrayToSave.push(this.state.displayValues[i].objectId)
    }
    console.log(arrayToSave)
    let Query = Parse.Object.extend(parseclass);
    let query = new Parse.Query(Query);
    let result = await query.get(parseobjectid)
    result.set(parsekey,arrayToSave)
    await result.save()
  }

  render() {
    const inputProps = {
      type: 'text',
      placeholder: 'Enter a value',
      className:'full-width'
    };
    console.log(this.state)
    return (
      <div className="autosuggest-container full-width">
        <Autocomplete
          inputProps={inputProps}
          getItemValue={(item) => item.name}
          items={this.state.values}
          value={this.state.value}
          shouldItemRender={this.matchValue}
          onChange={(event, value) => this.setState({ value })}
          onSelect={(value) => this.addItem(value)}
          renderMenu={children => (
            <div className="autosuggest-menu shadow">
              {children}
            </div>
          )}
          renderItem={(item, isHighlighted) => (
            <div
              className={`item ${isHighlighted ? 'item-highlighted' : ''}`}
              key={item.objectIds}
            >{item.name}</div>
          )}
        />
        {this.state.displayValues &&
          this.state.displayValues.map((data) =>{
            return(
              <div className="pill primary-background" style={{cursor:'initial'}}>
                {this.props.editLink ?
                  <div className="row">
                    <Link style={{color:"#fff"}} to={this.props.editLink+'?id='+data.objectId+'&redirectId='+this.props.parent}>
                      {data.name}
                    </Link>
                    <img className="circle" src="/images/close-black.png" style={{height:"20px",width:"20px", marginLeft:"20px", cursor:'pointer', padding:2}} onClick={() => {this.removeItem(data.name)}} />
                  </div>
                :
                <div className="row">
                  {data.name}
                  <img className="circle" src="/images/close-white.png" style={{height:"20px",width:"20px", marginLeft:"20px", cursor:'pointer', padding:2}} onClick={() => {this.removeItem(data.name)}} />
                </div>
                }
              </div>
            )
          })
        }
      </div>
    );
  }
}
