import React, { Component } from 'react';
import './logoSection.css';

export default class BiosSection extends Component {
  render() {
    return (
      <div className="section-padding full-width sectionContainer">
        <div className="max-width-container small-bottom-margin">
          <div className="section-padding no-top-padding">
            <div className="">
              <div className="row middle">
                <div
                  className="small-12 medium-6 centered-text full-height small-padding">
                  <img src={'design/vp-headshots.jpg'} className="full-width small-bottom-margin" alt="Example of headshots" />
                  <p className="subheading tiny-bottom-margin">{'VP Headshots'} </p>
                  <a className="downloadText" href="design/vp-headshots.zip">
                    Download
                    <img className="relative" src="./images/download-icon.png" alt="Download headshots" style={{ height: '1.2em', margin:"auto", top: '4px' }} />
                  </a>
                </div>
                <div
                  className="small-12 medium-6 centered-text full-height small-padding">
                  <img src={'design/team-bios.jpg'} className="full-width small-bottom-margin" alt="Example of team bios" />
                  <p className="subheading tiny-bottom-margin">{'Team Bios'} </p>
                  <a className="downloadText" href="design/team-bios.docx">
                    Download
                    <img className="relative" src="./images/download-icon.png" alt="Download bios" style={{ height: '1.2em', margin:"auto", top: '4px' }} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
