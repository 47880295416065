import Parse from 'parse';

export const ListCoverageSummaryPackages = async (admin) => {
  const Packages = Parse.Object.extend("CoveragePackages");
  const pkgs = new Parse.Query(Packages);
  pkgs.ascending("name")
  pkgs.ascending("order")
  pkgs.limit(1000)
  let results = await pkgs.find();
  if(!admin){
    results = results.filter((result) => result.attributes.status)
  }
  return results;
}

export const GetCoverageSummaryPackage = async (id) => {
  const Pkg = Parse.Object.extend("CoveragePackages");
  const pkg = new Parse.Query(Pkg);
  const result = await pkg.get(id);
  return result;
}

export const ListCoverageSummaryProducts = async (admin) => {
  const Products = Parse.Object.extend("CoverageProducts");
  const products = new Parse.Query(Products);
  products.ascending("name")
  products.limit(1000)
  let results = await products.find();
  if(!admin){
    results = results.filter((result) => result.attributes.status)
  }
  return results;
}

export const GetCoverageSummaryProduct = async (id) => {
  const Product = Parse.Object.extend("CoverageProducts");
  const product = new Parse.Query(Product);
  const result = await product.get(id);
  return result;
}

export const ListCoverageSummaryCompanies = async (admin) => {
  const Companies = Parse.Object.extend("CoverageCompanies");
  const companies = new Parse.Query(Companies);
  companies.ascending("name")
  companies.limit(10000)
  let results = await companies.find();
  if(!admin){
    results = results.filter((result) => result.attributes.status)
  }
  return results;
}

export const GetCoverageSummaryCompany = async (id) => {
  const Companies = Parse.Object.extend("CoverageCompanies");
  const companies = new Parse.Query(Companies);
  const result = await companies.get(id);
  return result;
}

export const ListWebsiteCustomJobs = async () => {
  const Jobs = Parse.Object.extend("WebsiteCustomJobs");
  const jobs = new Parse.Query(Jobs);
  jobs.ascending("name")
  jobs.limit(1000)
  let results = await jobs.find();
  return results;
}

export const GetWebsiteCustomJob = async (id) => {
  const Jobs = Parse.Object.extend("WebsiteCustomJobs");
  const jobs = new Parse.Query(Jobs);
  const result = await jobs.get(id);
  return result;
}

export const ListWebsiteJobDepartments = async () => {
  const Departments = Parse.Object.extend("WebsiteJobDepartments");
  const deparments = new Parse.Query(Departments);
  deparments.ascending("order")
  deparments.limit(1000)
  let results = await deparments.find();
  return results;
}


export const GetWebsiteJobDepartment = async (id) => {
  const Departments = Parse.Object.extend("WebsiteJobDepartments");
  const department = new Parse.Query(Departments);
  const result = await department.get(id);
  return result;
}

export const ListEmployeeNavigatorLinks = async () => {
  const Links = Parse.Object.extend("EmployeeNavigatorLinks");
  const links = new Parse.Query(Links);
  links.ascending("name")
  links.limit(1000)
  let results = await links.find();
  return results;
}

export const GetEmployeeNavigatorLink = async (id) => {
  const Link = Parse.Object.extend("EmployeeNavigatorLinks");
  const link = new Parse.Query(Link);
  const result = await link.get(id);
  return result;
}

export const ListEmployeeNavigatorCategories = async () => {
  const Categories = Parse.Object.extend("EmployeeNavigatorCategories");
  const categories = new Parse.Query(Categories);
  categories.ascending("name")
  categories.limit(1000)
  let results = await categories.find();
  return results;
}

export const GetEmployeeNavigatorCategory = async (id) => {
  const Category = Parse.Object.extend("EmployeeNavigatorCategories");
  const category = new Parse.Query(Category);
  const result = await category.get(id);
  return result;
}

export const UpdateDbItem = async (itemId,className,attributes) => {
  const Query = Parse.Object.extend(className);
  const query = new Parse.Query(Query);
  const result = await query.get(itemId);
  if(result){
    for (let i = 0; i < attributes.length; i++) {
      let key = attributes[i].key;
      let val = attributes[i].val;
      result.set(key,val)
    }
    result.save()
    return result;
  }
  return result;
}

export const DeleteDbItem = async (itemId,className) => {
  var Query = Parse.Object.extend(className);
  var query = new Parse.Query(Query);
  const result = await query.get(itemId);
  result.destroy()
  return false
}

export const CreateDbItem = async (className,attributes) => {
  const ClassItem = Parse.Object.extend(className);
  const item = new ClassItem;
  if(item){
    for (let i = 0; i < attributes.length; i++) {
      let key = attributes[i].key;
      let val = attributes[i].val;
      item.set(key,val)
    }
    await item.save()
    return item;
  }
  return false;
}
