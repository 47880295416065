import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";

export default class AdminWebsite extends Component {

  componentDidMount = async() =>{
    let val = await CheckVal();
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background centered-text">
          <h2 className="white">{'YipitData.com Admin'}</h2>
        </div>
        <div className="row">
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/departments">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Manage Job Departments'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Enter departments shown on website'}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/jobs">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Manage Custom Jobs'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Enter job not on Greenhouse'}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
