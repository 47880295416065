import React, { Component } from 'react';
import './logoSection.css';
import DesignSection from './designSection';

export default class LetterheadSection extends Component {
  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'Letterhead'}>
          <div className="max-width-container small-bottom-margin">
            <div className="section-padding no-top-padding">
              <div className="">
                <div className="row middle">
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/yipit-letterhead-cover.png'} className="full-width small-bottom-margin" alt="Example of the Edison letterhead" />
                    <p className="subheading tiny-bottom-margin">{'This letterhead comes with a full cover letter containing editable subtitles and date.'} </p>
                    <a className="downloadText" href="https://docs.google.com/document/d/1ivgbcS0VLitLyfkWvuUroUQqmIqVniFv/edit?usp=sharing&ouid=100410450017921786920&rtpof=true&sd=true" target="_blank">
                      View
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/yipit-letterhead-blankcover.png'} className="full-width small-bottom-margin" alt="Example of the Edison Trends letterhead" />
                    <p className="subheading tiny-bottom-margin">{'This letterhead comes with a blank cover letter with just the company logo.'} </p>
                    <a className="downloadText" href="https://docs.google.com/document/d/1k6NaLSznkek1fgmdFeRuT4fY9sF1CLjL/edit?usp=sharing&ouid=100410450017921786920&rtpof=true&sd=true" target="_blank">
                      View
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                  <div
                    className="small-12 medium-4 centered-text full-height small-padding">
                    <img src={'/design/yipit-letterhead-nocover.png'} className="full-width small-bottom-margin" alt="Example of the Edison Trends letterhead" />
                    <p className="subheading tiny-bottom-margin">{'This letterhead has no cover page attached.'} </p>
                    <a className="downloadText" href="https://docs.google.com/document/d/1ueqZ69ezRPYJRE5qFvtDVWY_FslVXXts/edit?usp=sharing&ouid=100410450017921786920&rtpof=true&sd=true" target="_blank">
                      View
                      <img className="relative" src="/images/download-icon.png" alt="Download" style={{ height: '1.2em',  margin:"auto", top: '4px', paddingLeft: "10px"}} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesignSection>
      </div>
    );
  }
}
