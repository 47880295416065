import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import BackButton from './../../components/backButton';
import {CreateDbItem} from './../../utils/dbFunctions';
import {withRouter} from "react-router-dom";

export class AdminCoverageSummaryAddProduct extends Component {

  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
  }
  nameChange = (e) =>{
    let value = e.target.value;
    this.setState({
      name:value
    })
  }

  onSave = async() =>{
    if(this.state.name){
      let item = await CreateDbItem('CoverageProducts',[{key:'name', val:this.state.name}]);
      if(item){
        this.props.history.push('/admin/product/'+item.id);
      } else{
        alert('Something very strange happened')
      }
    } else{
      alert('Enter a name and symbol to create a product')
    }
  }

  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <h2 className="white" style={{marginBottom:'0px'}}>{'Create a New Product'}</h2>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="small-max-width-container" style={{marginLeft:0}}>
            <h6 className="full-width subheading" style={{marginTop:"20px"}}>{'Name'}</h6>
            <input
              className="full-width tiny-padding light-grey-background"
              value={this.state.name}
              onChange={this.nameChange}
              placeholder="Enter a Title" />
              <h6 className="full-width subheading" style={{marginTop:"20px"}}>{' '}</h6>
              <div onClick={this.onSave} className="button primary-background white">{'Create'}</div>
          </div>
        </div>
      </div>
    )
  }
}


export default withRouter(AdminCoverageSummaryAddProduct)
