import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {ListCoverageSummaryCompanies} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';

export default class AdminCoverageSummaryCompanyList extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let items = await ListCoverageSummaryCompanies("admin");
    this.setState({
      items:items
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <div className="small-12 medium-10">
                <h2 className="white">{'Companies'}</h2>
              </div>
              <div className="small-12 medium-2 row" style={{alignItems:'center'}}>
                <Link to={"/admin/company/add"}>
                  <div className="full-width button white-background primary-color">{'Add New'}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          {this.state.items && this.state.items.map((item) =>{
            let name = item.attributes.name;
            let description = item.attributes.description;
            let image = item.attributes.image;
            if(image){
              image = image.url();
            }
            let icon = item.attributes.icon;
            if(icon){
              icon = icon.url();
            }
            let opacity = 1;
            if(!item.attributes.status){
              opacity = .5;
            }
            return(
              <Link to={'/admin/company/'+item.id}>
                <div className="row small-margin-bottom border-bottom" style={{opacity:opacity}}>
                  <div className="row" style={{alignItems:'center'}}>
                    {name && <h4 style={{padding:10}}>{name}</h4>}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </div>
    )
  }
}
