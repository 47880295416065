
var theme = {
  primaryBackground: '#fff',
  cardColor: '#fff',
  secondaryBackground: '#f9f9f9',
  primaryColor: '#0D4753',
  primaryTextColor: '#4e4e4e',
  secondaryTextColor: '#888888',
  orangeColor: '#FD9326',
  purpleColor: '#5C3BFA',
};

export const Colors = {
  primaryBackground: theme.primaryBackground,
  secondaryBackground: theme.secondaryBackground,
  primaryColor: theme.primaryColor,
  primaryTextColor: theme.primaryTextColor,
  secondaryTextColor: theme.secondaryTextColor,
  black: '#000',
  white: '#fff',
  orangeColor: theme.orangeColor,
  purpleColor: theme.purpleColor,
};

if (localStorage.getItem('theme') === 'dark') {
  theme = {
    primaryBackground: '#000',
    secondaryBackground: '#222',
    cardColor: '#333',
    primaryColor: '#0062e7',
    primaryTextColor: '#fff',
    secondaryTextColor: '#ccc',
  };
}

export const Theme = {
  cardColor: theme.cardColor,
  primaryBackground: theme.primaryBackground,
  secondaryBackground: theme.secondaryBackground,
  primaryColor: theme.primaryColor,
  primaryTextColor: theme.primaryTextColor,
  secondaryTextColor: theme.secondaryTextColor,
};

export const Values = {
  globalRadius: '40px',
};

export const HeaderHeight = 80;
export const MobileHeaderHeight = 80;

export const WebServerApiKey = 'bdH0VGExAEIhPq0z5vwdyVuHVzWx0hcR';
export const WebServerRoot = 'https://web-marketing.edison.tech/';

export const Products = [
  { value: 'mailIos', label: 'Edison Mail iOS' },
  { value: 'mailAndroid', label: 'Edison Mail Android' },
  { value: 'mailDesktop', label: 'Edison Mail Desktop' },
  { value: 'edisonTrends', label: 'Edison Trends' },
  { value: 'edisonBiz', label: 'Edison Business' },
  { value: 'pressReleases', label: 'Press Releases' },
  { value: 'onMail', label: 'OnMail' },
  { value: 'blog', label: 'Blog' },
];

export const NewsRoomOptions = [
  { value: ['mailIos', 'mailAndroid', 'mailDesktop'], label: 'Edison Mail' },
  { value: ['edisonTrends'], label: 'Edison Trends' },
  { value: ['onMail'], label: 'OnMail' },
];

export const BlogOptions = [
  { value: 'Tips & Tricks', label: 'Tips & Tricks' },
  { value: 'OnMail', label: 'OnMail' },
  { value: 'Edison Mail', label: 'Edison Mail' },
  { value: 'Email Matters', label: 'Email Matters' }
];

export const ACPW = 'YipitRocks';
