import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {ListCoverageSummaryPackages, ListCoverageSummaryProducts, ListCoverageSummaryCompanies} from './../../utils/dbFunctions';
import {TruncateDescription,ReturnProductColor} from './../../utils/functions';
import {Colors} from './../../utils/constants';
import MaterialIcon from 'material-icons-react';
import PortalModal from './../../components/portalModal';
import CompanyModal from './../../components/modals/companyModal';
import PackageModal from './../../components/modals/packageModal';
import ProductModal from './../../components/modals/productModal';

export default class CoverageSummarySearch extends Component {
  constructor(props) {
      super(props);
      this.state={
        updateKey:0
      };
  }
  componentDidMount = async() =>{
    let packages = await ListCoverageSummaryPackages();
    let products = await ListCoverageSummaryProducts();
    let companies = await ListCoverageSummaryCompanies();
    this.setState({
      products:products,
      allProducts:products,
      packages:packages,
      allPackages:packages,
      companies:companies,
      allCompanies:companies
    })
  }
  componentDidUpdate = () =>{
    let value = this.props.searchTerm;
    if(value){
      value = value.toLowerCase();
      if(value != this.state.value){

        let productResults = this.state.allProducts && this.state.allProducts.filter((product) => {
          let name = product.attributes.name;
          if(name){
            name = name.toLowerCase();
          }
          let symbol = product.attributes.symbol;
          if(symbol){
            symbol = symbol.toLowerCase();
          }
          let containsCompanyNames = [];
          let containsCompanySymbols = [];
          for (let i = 0; i < this.state.allCompanies.length; i++) {
            if(product.attributes.companies && product.attributes.companies.indexOf(this.state.allCompanies[i].id) != -1){
              if(this.state.allCompanies[i].attributes.name){
                containsCompanyNames.push(this.state.allCompanies[i].attributes.name.toLowerCase());
              }
              if(this.state.allCompanies[i].attributes.symbol){
                containsCompanySymbols.push(this.state.allCompanies[i].attributes.symbol.toLowerCase());
              }

            }
          }
          return (
            name && name.indexOf(value)!= -1 ||
            symbol && symbol.indexOf(value)!= -1 ||
            containsCompanyNames && containsCompanyNames.indexOf(value)!= -1  ||
            containsCompanySymbols && containsCompanySymbols.indexOf(value)!= -1
          )
        })

        let packageResults = this.state.allPackages && this.state.allPackages.filter((pkg) => {
          let name = pkg.attributes.name;
          if(name){
            name = name.toLowerCase();
          }
          return name && name.indexOf(value)!= -1;
        })

        let companyResults = this.state.allCompanies && this.state.allCompanies.filter((company) => {
          let name = company.attributes.name;
          let symbol = company.attributes.symbol;
          if(name){
            name = name.toLowerCase();
          }
          if(symbol){
            symbol = symbol.toLowerCase();
          }
          return ((name && name.indexOf(value)!= -1) || (symbol && symbol.indexOf(value)!= -1));
        })

        let results = [];
        if(productResults){
          for (let i = 0; i < productResults.length; i++) {
            let item = productResults[i];
            item.type = 'product'
            results.push(item)
          }
          for (let i = 0; i < companyResults.length; i++) {
            let item = companyResults[i];
            item.type = 'company'
            results.push(item)
          }
          for (let i = 0; i < packageResults.length; i++) {
            let item = packageResults[i];
            item.type = 'package'
            results.push(item)
          }
          results = results.sort((a, b) =>{
              let aName = a.attributes.symbol;
              let bName = b.attributes.symbol;
              if(!aName){
                aName = a.attributes.name
              }
              if(!bName){
                bName = b.attributes.name
              }
              if(a.attributes.name)
              if(aName < bName) { return -1; }
              if(aName > bName) { return 1; }
              return 0;
          })
          this.setState({
            results:results,
            products:productResults,
            packages:packageResults,
            companies:companyResults,
            value:value,
            updateKey:this.state.updateKey+1
          })
        }
      }
    }
     else{
      if(this.state.products != this.state.allProducts){
        this.setState({
          products:this.state.allProducts,
          value:value,
          updateKey:this.state.updateKey+1
        })
      } else if(this.state.packages != this.state.allpackages){
        this.setState({
          packages:this.state.allpackages,
          value:value,
          updateKey:this.state.updateKey+1
        })

      } else if (this.state.companies != this.state.allCompanies){
        this.setState({
          companies:this.state.allCompanies,
          value:value,
          updateKey:this.state.updateKey+1
        })
      }

    }
  }

  activatePackageModal = (group) =>{
    this.resetModals()
    this.setState({
      packageModalActive:true,
      packageModalId:group.id,
      packageModalContent:group
    })
  }
  hidePackageModal = () =>{
    this.setState({
      packageModalActive:false,
      packageModalId:null,
      packageModalContent:null
    })
  }

  activateProductModal = (product) =>{
    this.resetModals()
    this.setState({
      productModalActive:true,
      productModalId:product.id,
      productModalContent:product
    })
  }
  hideProductModal = () =>{
    this.setState({
      productModalActive:false,
      productModalId:null,
      productModalContent:null
    })
  }
  activateCompanyModal = (product) =>{
    this.resetModals()
    this.setState({
      companyModalActive:true,
      companyModalId:product.id,
      companyModalContent:product
    })
  }
  hideCompanyModal = () =>{
    this.setState({
      companyModalActive:false,
      companyModalId:null,
      companyModalContent:null
    })
  }
  resetModals = () =>{
    this.setState({
      productModalActive:false,
      packageModalActive:false,
      companyModalActive:false
    })
  }


  toggleListView = () =>{
    this.setState({
      listViewActive:true,
      gridViewActive:false,
      updateKey:this.state.updateKey+1
    })
  }

  toggleGridView = () =>{
    this.setState({
      listViewActive:false,
      gridViewActive:true,
      updateKey:this.state.updateKey+1
    })
  }

  render() {
    let toggleClass = this.state.gridViewActive ? 'grid-view-active' : 'list-view-active'
    return (
      <div className="full-height full-width">
        <div className="hero-padding primary-background centered-text design-site-mobile-hero-padding"  style={{backgroundImage:'url(/images/triangle-texture.png)'}}>
          <h3 className="white" style={{marginTop:0, marginBottom:0, paddingTop:50, paddingBottom:100}}>{'Coverage Summary'}</h3>
        </div>
        <div className="medium-padding centered-text">
          <div className="max-width-container tiny-padding">
            <div key={this.state.updateKey}>
              {this.props.searchTerm && this.state.results && this.state.results.map((item, index) =>{
                return(
                  <div className="tiny-padding" key={item.id}>
                    {item.type == 'product' &&  <ProductModal onPackageClick={this.activatePackageModal} onCompanyClick={this.activateCompanyModal} layout="horizontal" products={this.state.allProducts} packages={this.state.allPackages} companies={this.state.allCompanies} product={item} productId={item.id} />}
                    {/* item.type == 'company' &&  <CompanyModal onPackageClick={this.activatePackageModal} onProductClick={this.activateProductModal} layout="horizontal" products={this.state.allProducts} packages={this.state.allPackages} companies={this.state.allCompanies} company={item} companyId={item.id} /> */}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        {this.state.productModalActive &&
          <PortalModal
            modalHidden={this.hideProductModal}
            modalActive={this.state.productModalActive}
            content={
              <ProductModal
              onPackageClick={this.activatePackageModal}
              onCompanyClick={this.activateCompanyModal}
              companies={this.state.allCompanies}
              packages={this.state.allPackages}
              product={this.state.productModalContent}
              productId={this.state.productModalId} />
            }
          />
        }
      </div>
    )
  }
}
