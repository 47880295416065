import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./modal.css";

export default class extends Component {
	constructor() {
      super();
			this.state = ({
				modalActive:false
			})
  }
	componentDidMount = () =>{
		if(this.props.modalActive){
			const body = document.querySelector("body");
			body.style.overflow = "hidden";
			this.setState({
				modalActive:true,
				animateIn:true
			},()=>{
				setTimeout(() => {
					this.setState({
						animateIn:false
					})
				},10)
			})
		}
	}
	hideModal = () =>{
		this.setState({
			hideModal:true
		})
		setTimeout(() => {
			if(this.props.modalHidden){
				this.props.modalHidden()
			}
			const body = document.querySelector("body");
			body.style.overflow = "auto";
			this.setState({
				modalActive:false,
				hideModal:false
			})
		},500)
	}
	render() {
		var position = "fixed";
		if(this.props.positionType == "absolute"){
			position = "absolute";
		}
		var animateOut="";
		if(this.state.hideModal){
			animateOut ="animate-out"
		}
		var animateIn="";
		if(this.state.animateIn){
			animateIn ="animate-in"
		}
		if(!this.state.modalActive) return null
		var closeImage = "/images/close-white.png";
		var theme = "dark-theme";
		var additionalClasses = "";
		var xSize = "40";
		if(this.props.theme == "light"){
			closeImage = "/images/close-black.png";
			theme = "light-theme";
		}
		if(this.props.theme == "circled"){
			closeImage = "/images/close-black.png";
			theme = "light-theme";
			additionalClasses = 'shadow circle white-background';
			xSize = "30";
			if(window.innerWidth < 800){
				xSize = "20"
			}
		}
		let frameStyle = {maxWidth:'inherit', maxHeight:'inherit'};
		if(this.props.fullScreen){
			frameStyle = {maxWidth:'none', maxHeight:'none', padding:'0px', height:'100%'}
		}
		let maxWidthOverride = this.props.maxWidthOverride ? 'full-screen-override' : '';
		return ReactDOM.createPortal(
			<div  className={"modal-container "+animateOut+" "+animateIn}>
 			 <div className={"modal-foreground-wrapper"}>
 				 <div className="modal centered-text" style={frameStyle}>
				 	<span id="modal-content-wrapper">
				 		<span id="modal-content" className={"modal-content "+maxWidthOverride}>{this.props.content}</span>
					</span>
				</div>
 			 </div>
			 <div onClick={this.hideModal} className={"modal-background"} style={{position:position}}></div>
 		 </div>,
		 document.getElementById('modal-root')
	 );
	}
}
