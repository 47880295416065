import React, { Component } from 'react';
import {Link} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {ListEmployeeNavigatorLinks} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';

export default class AdminEmployeeNavigatorLinkList extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let items = await ListEmployeeNavigatorLinks("admin");
    this.setState({
      items:items
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <div className="small-12 medium-10">
                <h2 className="white">{'Links'}</h2>
              </div>
              <div className="small-12 medium-2 row" style={{alignItems:'center'}}>
                <Link to={"/admin/link/add"}>
                  <div className="full-width button primary-color white-background">{'Add New'}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="max-width-container" style={{marginLeft:0}}>
            {this.state.items && this.state.items.map((item) =>{
              let name = item.attributes.name;
              let link = item.attributes.link;
              let image = item.attributes.imageUrl;
              let opacity = 1;
              if(!item.attributes.status){
                opacity = .5;
              }
              return(
                <Link to={'/admin/link/'+item.id}>
                  <div className="row small-margin-bottom border-bottom full-width" style={{opacity:opacity}}>
                    <div style={{height:120, width:120, padding:10,marginRight:20, backgroundColor:'#f9f9f9'}}>
                      <img className="full-width" src={'/images/square.png'} />
                    </div>
                    <div>
                      {name && <h4>{name}</h4>}
                      {link && <p className="subheading">{link}</p>}
                    </div>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
