import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

/* Components */
import Header from './components/header';

/*Admin Pages */
import AdminLogin from './pages/adminLogin';
import Admin from './pages/admin';
import AdminProcess from './pages/adminProcess';

/* Report Builder Admin */
import AdminReportList from './pages/adminReportList';
import AdminReportAdd from './pages/adminReportAdd';
import AdminReportEdit from './pages/adminReportEdit';
import AdminReportDetail from './pages/adminReportDetail';
import AdminReportDetailUnstyled from './pages/adminReportDetailUnstyled';

/* Website Dashboard Admin */
import AdminWebsite from './pages/adminWebsite';
import AdminWebsiteCustomJobList from './pages/adminWebsiteCustomJobList';
import AdminWebsiteAddCustomJob from './pages/adminWebsiteAddCustomJob';
import AdminWebsiteEditCustomJob from './pages/adminWebsiteEditCustomJob';
import AdminWebsiteJobDepartmentsList from './pages/adminWebsiteJobDepartmentsList';
import AdminWebsiteAddJobDepartment from './pages/adminWebsiteAddJobDepartment';
import AdminWebsiteEditJobDepartment from './pages/adminWebsiteEditJobDepartment';

/* Employee Navigator Admin */
import AdminEmployeeNavigator from './pages/adminEmployeeNavigator';
import AdminEmployeeNavigatorLinkList from './pages/adminEmployeeNavigatorLinkList';
import AdminEmployeeNavigatorEditLink from './pages/adminEmployeeNavigatorEditLink';
import AdminEmployeeNavigatorAddLink from './pages/adminEmployeeNavigatorAddLink';
import AdminEmployeeNavigatorCategoryList from './pages/adminEmployeeNavigatorCategoryList';
import AdminEmployeeNavigatorEditCategory from './pages/adminEmployeeNavigatorEditCategory';
import AdminEmployeeNavigatorAddCategory from './pages/adminEmployeeNavigatorAddCategory';

/* Product Dashboard Admin */
import AdminProductDashboard from './pages/adminProductDashboard';
import AdminCoverageSummary from './pages/adminCoverageSummary';
import AdminCoverageSummaryProductList from './pages/adminCoverageSummaryProductList';
import AdminCoverageSummaryAddProduct from './pages/adminCoverageSummaryAddProduct';
import AdminCoverageSummaryEditProduct from './pages/adminCoverageSummaryEditProduct';
import AdminCoverageSummaryPackageList from './pages/adminCoverageSummaryPackageList';
import AdminCoverageSummaryAddPackage from './pages/adminCoverageSummaryAddPackage';
import AdminCoverageSummaryEditPackage from './pages/adminCoverageSummaryEditPackage';
import AdminCoverageSummaryCompanyList from './pages/adminCoverageSummaryCompanyList';
import AdminCoverageSummaryAddCompany from './pages/adminCoverageSummaryAddCompany';
import AdminCoverageSummaryEditCompany from './pages/adminCoverageSummaryEditCompany';

/* Public Pages */
import EmployeeNavigator from './pages/employeeNavigator';
import CoverageSummary from './pages/coverageSummary';
import CoverageSummarySearch from './pages/coverageSummarySearch';
import CoverageSummaryPrint from './pages/coverageSummaryPrint';
import CoverageSummaryPrintLarge from './pages/coverageSummaryPrintLarge';
import DesignDashboard from './pages/designDashboard';

/* General */
import FourZeroFour from './pages/fourZeroFour';

export default class App extends Component {
  constructor() {
    super();
    this.state = {
      searchTerm:undefined
    };
  }

  componentDidMount = async () => {
    this.setState({
      ready: true,
    });
  };
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
    let container = document.getElementById('page-content');
    if (container) {
      container.classList.add('active');
    }
  };

  onSearch = (term) =>{
    this.setState({
      searchTerm:term
    })
  }


  render() {
    if (!this.state.ready) return null;

    //Uncomment the next line to see the email site
    //window.site = 'email';

    //Uncomment the next line to see the onmail site
    //window.site = 'onMail';

    let site = null;
    //Uncomment the next line to see the report builder
    //site = 'report-builder';

    //Uncomment the next line to see the design site
    //site = 'design-dashboard';

    //Uncomment the next line to see the product catalog
    site = 'coverage-summary';

    //Uncomment the next line to see the employee navigator
    //site = 'employee-navigator';

    //Uncomment the next line to see the link dashboard
    //site = 'website-admin';

    if(
      window.location && window.location.href && window.location.href.indexOf('yipit-report-builder') != -1){
      site = 'report-builder'
    }
    if(
      window.location && window.location.href && window.location.href.indexOf('yipit-design-dashboard') != -1 ||
      window.location && window.location.href && window.location.href.indexOf('design.yipitdata.com') != -1){
      site = 'design-dashboard'
    }
    if(window.location && window.location.href && window.location.href.indexOf('yipit-employee-navigator') != -1){
      site = 'employee-navigator'
    }
    if(window.location && window.location.href && window.location.href.indexOf('yipit-product-dashboard') != -1 ||
      window.location && window.location.href && window.location.href.indexOf('coverage-summary.yipitdata.com') != -1){
      site = 'coverage-summary'
    }
    if(window.location && window.location.href && window.location.href.indexOf('yipit-website-admin') != -1 ||
      window.location && window.location.href && window.location.href.indexOf('website-admin.yipitdata.com') != -1){
      site = 'website-admin'
    }

    return (
      <Route
        history={this.props.history}
        onChange={this.onRouteChange}
        render={({ location }) => (
          <div className={'full-height full-width standard-page-container'} style={{height:"100%"}}>
            {site != 'design-dashboard' && <Header site={site} onSearch={this.onSearch} />}
            <div id="page-content" style={{height:"100%"}}>
              <Switch>
                /* Admin Shared Pages */
                <Route exact path="/adminLogin" render={(props) => <AdminLogin />} />
                <Route exact path="/adminProcess" render={(props) => <AdminProcess />} />

                /* Coverage Summary Pages */
                {site == 'coverage-summary' && <Route exact path="/" render={(props) => <CoverageSummary {...props} />} />}
                {site == 'coverage-summary' && <Route exact path="/search" render={(props) => <CoverageSummarySearch searchTerm={this.state.searchTerm} />} />}
                {site == 'coverage-summary' && <Route exact path="/print" render={(props) => <CoverageSummaryPrint />} />}
                {site == 'coverage-summary' && <Route exact path="/printLarge" render={(props) => <CoverageSummaryPrintLarge />} />}
                {site == 'coverage-summary' && <Route exact path="/admin" render={(props) => <AdminCoverageSummary />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/products" render={(props) => <AdminCoverageSummaryProductList />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/product/add" render={(props) => <AdminCoverageSummaryAddProduct />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/product/:id" render={(props) => <AdminCoverageSummaryEditProduct />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/packages" render={(props) => <AdminCoverageSummaryPackageList />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/package/add" render={(props) => <AdminCoverageSummaryAddPackage />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/package/:id" render={(props) => <AdminCoverageSummaryEditPackage />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/companies" render={(props) => <AdminCoverageSummaryCompanyList />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/company/add" render={(props) => <AdminCoverageSummaryAddCompany />} />}
                {site == 'coverage-summary' && <Route exact path="/admin/company/:id" render={(props) => <AdminCoverageSummaryEditCompany />} />}

                /* Design Dashboard Pages */
                {site == 'design-dashboard' && <Route exact path="/" render={(props) => <DesignDashboard {...props} />} />}
                {site == 'design-dashboard' && <Route exact path="/design/:page" render={(props) => <DesignDashboard {...props}  />} />}

                /* Report Builder */
                {site == 'report-builder' && <Route exact path="/" render={(props) => <AdminReportList />} />}
                {site == 'report-builder' && <Route exact path="/report/add" render={(props) => <AdminReportAdd />} />}
                {site == 'report-builder' && <Route exact path="/reportEdit" render={(props) => <AdminReportEdit />} />}
                {site == 'report-builder' && <Route exact path="/reportDetail" render={(props) => <AdminReportDetail />} />}
                {site == 'report-builder' && <Route exact path="/reportDetailUnstyled" render={(props) => <AdminReportDetailUnstyled />} />}

                /* Website Admin Pages */
                {site == 'website-admin' && <Route exact path="/" render={(props) => <AdminWebsite />} />}
                {site == 'website-admin' && <Route exact path="/admin/jobs" render={(props) => <AdminWebsiteCustomJobList />} />}
                {site == 'website-admin' && <Route exact path="/admin/job/add" render={(props) => <AdminWebsiteAddCustomJob />} />}
                {site == 'website-admin' && <Route exact path="/admin/job/:id" render={(props) => <AdminWebsiteEditCustomJob />} />}
                {site == 'website-admin' && <Route exact path="/admin/departments" render={(props) => <AdminWebsiteJobDepartmentsList />} />}
                {site == 'website-admin' && <Route exact path="/admin/department/add" render={(props) => <AdminWebsiteAddJobDepartment />} />}
                {site == 'website-admin' && <Route exact path="/admin/department/:id" render={(props) => <AdminWebsiteEditJobDepartment />} />}


                /* Link Dashboard Pages */
                {site == 'employee-navigator' && <Route exact path="/" render={(props) => <EmployeeNavigator {...props} />} />}
                {site == 'employee-navigator' && <Route exact path="/admin" render={(props) => <AdminEmployeeNavigator />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/links" render={(props) => <AdminEmployeeNavigatorLinkList />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/link/add" render={(props) => <AdminEmployeeNavigatorAddLink />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/link/:id" render={(props) => <AdminEmployeeNavigatorEditLink />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/categories" render={(props) => <AdminEmployeeNavigatorCategoryList />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/category/add" render={(props) => <AdminEmployeeNavigatorAddCategory />} />}
                {site == 'employee-navigator' && <Route exact path="/admin/category/:id" render={(props) => <AdminEmployeeNavigatorEditCategory />} />}

                /* General */
                <Route render={(props) => <FourZeroFour />} />

              </Switch>
            </div>
          </div>
        )}
      />
    );
  }
}
