import React, { Component } from 'react';
import {Link} from "react-router-dom";

export default class AdminProductDashboard extends Component {
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background centered-text">
          <h2 className="white">{'Admin'}</h2>
        </div>
        <div className="row">
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/products">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Companies'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Manage company information'}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/groups">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Packages'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Manage and create product groups'}</p>
                </div>
              </div>
            </Link>
          </div>
          <div className="small-12 medium-4 tiny-padding">
            <Link to="/admin/bundles">
              <div className="shadow">
                <div className="small-padding primary-background centered-text">
                  <h3 className="white">{'Bundles'}</h3>
                </div>
                <div className="small-padding centered-text">
                  <p className="subheading">{'Manage and create product bundles'}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}
