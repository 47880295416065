import React, { Component } from 'react';
import "./fullPageBreak.css";

export default class fullPageBreak extends Component {
  constructor(props) {
      super(props);
  }
  render() {
    let title = this.props.title;
    let description = this.props.description;
    let backgroundColor = this.props.backgroundColor;
    let textColor = this.props.textColor;

    return (
      <div className="full-page-break-container medium-padding" style={{"backgroundColor":backgroundColor}}>
        <div className="full-page-break-container-text">
          <h1 className="tiny-bottom-margin" style={{"color":textColor}}>{title}</h1>
          {description && <p className="small-bottom-margin" style={{"color":textColor}}>{description}</p>}
        </div>
      </div>
    );
  }
}
