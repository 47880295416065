import React, { Component } from 'react';
import {GetAllBlogPosts, FormatDate} from './../../utils/functions';
import Parse from 'parse';
import {withRouter, Link,Redirect} from "react-router-dom";

class AdminReportList extends Component {
  constructor(props) {
      super(props);
      this.state={
        tags:[]
      };
  }
  componentDidMount = async() =>{
    const Query = Parse.Object.extend('YipitReports');
    var query = new Parse.Query(Query);
    let reports = await query.find()
    this.setState({
      reports:reports
    })
  }
  render() {
    return (
      <div className="large-padding light-grey-background">
        <div className="max-width-container">
          <div className="row" style={{marginBottom:"10px"}}>
            <div className="small-12 medium-10">
              <h2>{'Reports'}</h2>
            </div>
            <div className="small-12 medium-2">
              <Link to={"/report/add"} style={{marginTop:"5px"}}>
                <div className="full-width button primary-background white">{'Add New'}</div>
              </Link>
            </div>
          </div>
          <div className="white-background tiny-padding shadow">
            {this.state.reports && this.state.reports.map((data) =>{
              let title = data.attributes.title;
              let date = data.attributes.date;
              let formattedDate = null;
              console.log(data.attributes.date)
              if(data.attributes.date){
                formattedDate = FormatDate(data.attributes.date)
              }

              return(
                <div className="border-bottom">
                  <Link to={"/reportEdit?id="+data.id}>
                    <div className="row">
                      <div className="small-12 medium-10 tiny-padding row">
                        <div className="full-width" style={{margin:"auto"}}>
                          {title && <h5 style={{color:"#000"}}>{title}</h5>}
                          {formattedDate && <p style={{color:"#000"}} className="subheading">{formattedDate}</p>}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(AdminReportList)
