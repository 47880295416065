import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {GetCoverageSummaryProduct,ListCoverageSummaryCompanies,ListCoverageSummaryPackages} from './../../utils/dbFunctions';

export class ProductModal extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = async() =>{
    let product = this.props.product;
    let productId = this.props.productId;
    if(!product){
      product = await GetCoverageSummaryProduct(productId);
    }
    let companies = this.props.companies;
    if(!companies){
      companies = await ListCoverageSummaryCompanies();
    }
    let pkgs = this.props.packages;
    if(!pkgs){
      pkgs = await ListCoverageSummaryPackages();
    }
    let includedInPackages = pkgs.filter((pkg) => pkg.attributes.products && pkg.attributes.products.indexOf(product.id) != -1);
    let containsCompanies = [];
    for (let i = 0; i < companies.length; i++) {
      if(product.attributes.companies){
        if(product.attributes.companies.indexOf(companies[i].id) != -1){
          containsCompanies.push(companies[i]);
        }
      }
    }
    this.setState({
      product:product,
      companies:containsCompanies,
      packages:includedInPackages
    })
  }
  render() {
    let item = this.state.product;
    let color = "#999";
    if(this.state.packages && this.state.packages[0]){
      color = this.state.packages[0].attributes.color
    }
    if(this.props.layout != 'horizontal'){
      return(
        <div className="small-max-width-container">
          <div className="shadow">
            <div className="small-padding" style={{backgroundColor:color}}>
              {item && item.attributes &&
                <h3 className="white">{item.attributes.symbol ? item.attributes.symbol : item.attributes.name}</h3>
              }
            </div>
            <div className="small-padding white-background left-text">
              {item && item.attributes && item.attributes.subheading &&
                <p style={{marginBottom:10}}><b>{item.attributes.subheading}</b></p>
              }
              {item && item.attributes && item.attributes.description &&
                <p className="subheading preserve-space">{item.attributes.description}</p>
              }
              <div className="row">
                {this.state.packages &&
                  <div className="small-12 medium-6">
                    <p style={{marginTop:20}}><b>{'Package'}</b></p>
                    {this.state.packages && this.state.packages.map((data) =>{
                      return(<span onClick={()=> this.props.onPackageClick && this.props.onPackageClick(data)} className="label" style={{backgroundColor:data.attributes.color, color:'#fff', cursor:'pointer'}}>{data.attributes.name}</span>)
                    })}
                  </div>
                }
                {this.state.companies && this.state.companies.length > 1 &&
                  <div className="small-12 medium-6">
                    <p style={{marginTop:20}}><b>{'Coverage'}</b></p>
                    {this.state.companies && this.state.companies.map((data) =>{
                      return(<span style={{cursor:'pointer'}}  onClick={()=> this.props.onCompanyClick && this.props.onCompanyClick(data)} className="label">{data.attributes.symbol ? data.attributes.symbol : data.attributes.name }</span>)
                    })}
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      )
    } else{
      return(
        <div className="row shadow">
          <div className="small-4 medium-2 small-padding row" style={{backgroundColor:color, alignItems:'center', justifyContent:'center'}}>
            <div>
              {item && item.attributes &&
                <h5 className="white">{item.attributes.symbol ? item.attributes.symbol : item.attributes.name}</h5>
              }
            </div>
          </div>
          <div className="small-8 medium-10 small-padding white-background left-text">
            {item && item.attributes && item.attributes.name &&
              <h4>
              {item.attributes.symbol &&
                <span>
                  {item.attributes.symbol}
                  <span className="subheading">{' | '}></span>
                </span>
              }
              {item.attributes.name}
              </h4>
            }
            {item && item.attributes && item.attributes.subheading &&
              <p><b>{item.attributes.subheading}</b></p>
            }
            {item && item.attributes && item.attributes.description &&
              <p className="subheading preserve-space">{item.attributes.description}</p>
            }
            <div className="row">
              {this.state.packages &&
                <div className="small-12 medium-6">
                  <p style={{marginTop:20}}><b>{'Package'}</b></p>
                  {this.state.packages && this.state.packages.map((data) =>{
                    return(<span onClick={()=> this.props.onPackageClick && this.props.onPackageClick(data)} className="label" style={{backgroundColor:data.attributes.color, color:'#fff', cursor:'pointer'}}>{data.attributes.name}</span>)
                  })}
                </div>
              }
              {this.state.companies && this.state.companies.length > 1 &&
                <div className="small-12 medium-6">
                  <p style={{marginTop:20}}><b>{'Coverage'}</b></p>
                  {this.state.companies && this.state.companies.map((data) =>{
                    return(<span style={{cursor:'pointer'}}  onClick={()=> this.props.onCompanyClick && this.props.onCompanyClick(data)} className="label">{data.attributes.symbol ? data.attributes.symbol : data.attributes.name}</span>)
                  })}
                </div>
              }
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ProductModal)
