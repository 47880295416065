import React, { Component } from 'react';
import {Link, Redirect} from "react-router-dom";
import {CheckVal} from "./../../utils/functions";
import {ListCoverageSummaryProducts} from './../../utils/dbFunctions';
import BackButton from './../../components/backButton';

export default class AdminCoverageSummaryProductList extends Component {
  constructor(props) {
      super(props);
      this.state={
        items:[]
      };
  }
  componentDidMount = async() =>{
    let val = await CheckVal();
    let items = await ListCoverageSummaryProducts("admin");
    this.setState({
      items:items
    })
  }
  render() {
    return (
      <div className="full-height full-width">
        <div className="admin-hero-padding primary-background">
          <div className="small-padding">
            <BackButton />
            <div className="row" style={{marginBottom:"10px"}}>
              <div className="small-12 medium-10">
                <h2 className="white">{'Products'}</h2>
              </div>
              <div className="small-12 medium-2 row" style={{alignItems:'center'}}>
                <Link to={"/admin/product/add"}>
                  <div className="full-width button white-background primary-color">{'Add New'}</div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="small-padding">
          <div className="small-max-width-container" style={{marginLeft:0}}>
            {this.state.items && this.state.items.map((item) =>{
              let name = item.attributes.name;
              let opacity = 1;
              if(!item.attributes.status){
                opacity = .5;
              }
              return(
                <Link to={'/admin/product/'+item.id}>
                  <div className="row small-margin-bottom border-bottom" style={{opacity:opacity}}>
                    <h5><span style={{opacity: opacity}} className="subheading">{name}</span></h5>
                  </div>
                </Link>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
