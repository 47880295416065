import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {GetCoverageSummaryCompany, ListCoverageSummaryPackages,ListCoverageSummaryProducts} from './../../utils/dbFunctions';
import {ReturnProductColor}  from './../../utils/functions';

export class ComapanyModal extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = async() =>{
    let company = this.props.company;
    let companyId = this.props.companyId;
    if(!company){
      company = await GetCoverageSummaryCompany(id);
    }
    let products = this.props.products;
    if(!products){
      products = await ListCoverageSummaryProducts();
    }
    let packages = this.props.packages;
    if(!packages){
      packages = await ListCoverageSummaryPackages();
    }
    let includedInProducts = products.filter((prod) => prod.attributes.companies && prod.attributes.companies.indexOf(company.id) != -1);
    let includedInPackages = [];
    for (let i = 0; i < packages.length; i++) {
      let packageProducts = packages[i].attributes.products;
      for (let x = 0; x < includedInProducts.length; x++) {
        if(packageProducts && packageProducts.indexOf(includedInProducts[x].id) != -1){
          if(includedInPackages && includedInPackages.indexOf(packages[i]) == -1){
            includedInPackages.push(packages[i])
          }
        }
      }
    }
    this.setState({
      packages:packages,
      includedInPackages:includedInPackages,
      company:company,
      products:products,
      includedInProducts:includedInProducts
    })

  }
  render() {
    let item = this.state.company;
    let color = '#999';

    if(this.props.layout == 'horizontal'){
      return(
        <div>
          <div className="row shadow">
            <div className="small-4 medium-2 medium-padding primary-background row" style={{alignItems:'center', justifyContent:'center'}}>
              <div className="tiny-padding" style={{backgroundColor:color}}>
                {item && item.attributes &&
                  <h3 className="white">{item.attributes.symbol ? item.attributes.symbol : item.attributes.name}</h3>
                }
              </div>
            </div>
            <div className="small-8 medium-10 small-padding white-background left-text">
              {item && item.attributes && item.attributes.name &&
                <h5 style={{marginTop:0, marginBottom:10}}>{item.attributes.name}</h5>
              }
              {this.state.includedInProducts && this.state.includedInProducts.map((data) =>{
                let packageColor = '#999';
                packageColor = ReturnProductColor(data, this.state.packages);
                return(
                  <div style={{cursor:'pointer', marginTop:30}}  onClick={()=> this.props.onProductClick && this.props.onProductClick(data)}>
                      <h6>
                        <div style={{height:10, width:10, marginRight:5, display:'inline-block', backgroundColor:packageColor}} />
                        {data.attributes.name}
                      </h6>
                      <p className="subheading preserve-space">{data.attributes.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    } else{
      return(
        <div className="small-max-width-container">
          <div className="shadow">
            <div>
              <div className="small-padding" style={{backgroundColor:color}}>
                {item && item.attributes &&
                  <h3 className="white">{item.attributes.symbol ? item.attributes.symbol : item.attributes.name}</h3>
                }
              </div>
            </div>
            <div className="small-padding white-background left-text">
              {item && item.attributes && item.attributes.name &&
                <h5 style={{marginTop:0, marginBottom:10}}>{item.attributes.name}</h5>
              }
              {this.state.includedInProducts && this.state.includedInProducts.map((data) =>{
                let packageColor = '#999';
                packageColor = ReturnProductColor(data, this.state.packages);
                return(
                  <div style={{cursor:'pointer', marginTop:30}}  onClick={()=> this.props.onProductClick && this.props.onProductClick(data)}>
                      <h6>
                      <div style={{height:10, width:10, marginRight:5, display:'inline-block', backgroundColor:packageColor}} />
                      {data.attributes.name}</h6>
                      <p className="subheading preserve-space">{data.attributes.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(ComapanyModal)
