import React, { Component } from 'react';
import {GetUrlVariable} from './../../utils/functions';
import TextOverImageBlock from './../../components/textOverImageBlock';
import ParseEditableInput from './../../components/parseEditableFormElements/input';
import ParseEditableTextarea from './../../components/parseEditableFormElements/textArea';
import {Link, Redirect} from 'react-router-dom';
import Parse from 'parse';

export default class Admin extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }

  render() {
    if(!Parse.User.current()){
       return <Redirect to="/adminLogin" />
    } else if(Parse.User.current().id != "7LDjDRtq8j"){
      return <Redirect to="/adminLogin" />
    } else{
      return (
        <div className="large-padding light-grey-background">
          <div className="max-width-container white-background tiny-padding shadow">
            <h3>{'Welcome to the coolest admin site ever'}</h3>
            <p>{'My mom said so, so it must be true'}</p>
          </div>
          <div className="max-width-container white-background tiny-padding shadow">
            <div className="small-padding">
              <h4><Link to={'/adminBlogList'}>{'Edit Blog'}</Link></h4>
            </div>
            <hr className="subheading" />
            <div className="small-padding">
              <h4><Link to={'/adminTextEdit'}>{'Edit Site Text'}</Link></h4>
            </div>
            <hr className="subheading" />
            <div className="small-padding">
              <h4><Link to={'/adminNewsList'}>{'Edit News'}</Link></h4>
            </div>
          </div>
        </div>
      )
    }
  }
}
