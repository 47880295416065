import React, { Component } from 'react';
import {withRouter} from "react-router-dom";

export class BackButton extends Component {

  goBack = () =>{
    this.props.history.goBack()
  }
  render() {
    return(
      <div onClick={this.goBack} className="circle white-background shadow" style={{display:'flex', height:50, width:50, padding:5,cursor:'pointer'}}>
        <img className="full-width" src="/images/back-black.png" />
      </div>
    )
  }
}

export default withRouter(BackButton)
