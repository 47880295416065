import React, { Component } from 'react';
import DesignSection from './designSection';

export default class FontSection extends Component {
  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'Our Font'}>
          <div className="max-width-container">
            <div className="row">
              <div className="small-12 medium-4">
                <img className="full-width" src="/images/font-kicker.png" />
              </div>
              <div className="small-12 medium-8 row" style={{alignItems:'center'}}>
                <div className="full-width small-padding">
                  <h3 style={{marginTop:0, marginBottom:0}}>{'Roboto'}</h3>
                  <h5 style={{marginTop:10}}><b>{'Professional and Readable'}</b></h5>
                  <p className="subheading">{`Roboto has a dual nature. It has a mechanical skeleton and the forms are largely geometric. At the same time, the font features friendly and open curves.`}</p>
                  <p style={{marginTop:20}}><span className="subheading">{'These fonts are licensed under the '}</span><a target="_blank" href="https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL">{'Open Font License'}</a>{'.'}</p>
                  <div className="row" style={{marginTop:20}}>
                    <div style={{marginRight:10}}>
                      <a href="/design/roboto.zip" download>
                        <div className="button primary-background white">{'Download Font'}</div>
                      </a>
                    </div>
                    <a target="_blank" href="https://fonts.google.com/specimen/Roboto#standard-styles">
                      <div className="button primary-background white">{'View on Google'}</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DesignSection>
      </div>
    );
  }
}
