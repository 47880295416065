import React, { Component } from 'react';
import Parse from 'parse';
import './parseEditableImage.css';

export default class ParseEditableImage extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = () =>{
    this.updateData();
  }
  updateData = () =>{
    var id = this.state.id;
    var Query = Parse.Object.extend(this.props.parseclass);
    var query = new Parse.Query(Query);
    query.get(this.props.parseobjectid,{
      success: (result) => {
          this.setState({
            result:result,
            uploading:false
          })
      },
      error: function(error) {
          console.log("Error: " + error.code + " " + error.message);
      }
    });
  }

  onPhotoAdded =() =>{
    alert('added')
    this.setState({
      uploading:true
    })
    var addPhoto = document.getElementById(this.props.parseclass+this.props.parsekey+"-photo");
    var parseFile = new Parse.File("photo.jpg", addPhoto.files[0]);
    parseFile.save().then(()=> {
      var Query = Parse.Object.extend(this.props.parseclass);
      var query = new Parse.Query(Query);
      query.get(this.props.parseobjectid, {
        success: (data) => {
        if(parseFile){
          data.set(this.props.parsekey, parseFile);
        }
        data.save(null, {
          success: (result) => {
            console.log(result)
            this.updateData();
          },
          error: (data, error) => {
            console.log(error)
          }
        });
        }
      })
    });
  }

  uploadClicked = () =>{
    document.getElementById(this.props.parseclass+this.props.parsekey+"-photo").click();
  }

  render() {
    if(!this.state.result) return null;
    var resizeMode = "background-image-fill"
    if(this.props.resizeMode == "fit"){
      resizeMode = "background-image-fit"
    }
    var ratio = '/images/square.png';
    if(this.props.ratio == 'rectangle'){
      ratio = '/images/rectangle.png';
    }
    if(this.props.ratio == 'sliver'){
      ratio = '/images/sliver.png';
    }
    var defaultImage = '/images/image-placeholder.png';
    if(this.state.uploading){
      defaultImage = '/images/loading.gif';
    }
    var imageTitle = 'Upload Image';
    if(this.state.imageTitle){
      defaultImage = imageTitle;
    }
    return (
      <div className="full-width image-placeholder-background image-upload-container" style={{backgroundImage:"url("+defaultImage+")"}}>
        {!this.state.uploading && this.state.result.attributes[this.props.parsekey] ?
          <img
            src={ratio}
            style={{'background-image':'url('+this.state.result.attributes[this.props.parsekey].url()+')'}}
            className={"full-width "+resizeMode}
          />
          :
          <img
            src={ratio}
            className={"full-width background-image-fit"}
          />
        }
        <div className="full-width image-upload-container-upload-button-container" onClick={this.uploadClicked}>
          <div className="button">{imageTitle}</div>
        </div>
        <input id={this.props.parseclass+this.props.parsekey+"-photo"} className="hidden" type="file" name="cover" onChange={this.onPhotoAdded} />
      </div>
    );
  }
}
