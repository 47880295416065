import './styles/app.css';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app';
import createBrowserHistory from 'history/createBrowserHistory';
import Parse from 'parse';
import { ParallaxProvider } from 'react-scroll-parallax';
Parse.initialize('YipitInternalTools', 'HNurCDz2LOyVyRejaou9HCwTgGGyiU7H');
Parse.serverURL = 'https://coverage-summary-api.yipitdata.com/api';

const history = createBrowserHistory();

const renderApp = (Component) =>
  render(
    <Router history={history}>
      <ParallaxProvider>
        <Component />
      </ParallaxProvider>
    </Router>,
    document.getElementById('root')
  );

renderApp(App);
