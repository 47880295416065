import React, { Component } from 'react';
import "./header.css";
import DesignDashboardHeader from "./designDashboardHeader";
import LinkDashboardHeader from "./linkDashboardHeader";
import CoverageSummaryHeader from "./coverageSummaryHeader";

export default class Header extends Component {
  constructor(props) {
      super(props);
      this.state = {
        active:false
      }
  }
  componentDidMount = () =>{
		window.addEventListener("scroll", this.onScroll);
	}
	componentWillUnmount = () =>{
		window.removeEventListener("scroll", this.onScroll);
	}

	onScroll = (e) => {
		let distance = document.documentElement.scrollTop;
		if(distance === 0){
			distance = document.body.scrollTop;
		}
		if(distance < (80)){
			this.setState({
				active:false
			})
		} else{
			if(!this.state.active){
				this.setState({
					active:true
				})
			}
		}
	}

  render() {
    let activeClass = "";
    if(this.state.active){
      activeClass = "active"
    }

    return (
      <div className={"header-container "+activeClass}>
        {this.props.site === "design-dashboard" && <DesignDashboardHeader  active={this.state.active} />}
        {this.props.site === "link-dashboard" && <LinkDashboardHeader active={this.state.active} />}
        {this.props.site === "coverage-summary" && <CoverageSummaryHeader onSearch={this.props.onSearch} active={this.state.active} />}
      </div>
    );
  }
}
