import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import {GetCoverageSummaryPackage,ListCoverageSummaryProducts,ListCoverageSummaryCompanies} from './../../utils/dbFunctions';

export class PackageModal extends Component {
  constructor(props) {
      super(props);
      this.state={};
  }
  componentDidMount = async() =>{
    let pkg = this.props.pkg;
    let id = this.props.packageId;
    if(!pkg){
      pkg = await GetCoverageSummaryPackage(id);
    }
    let products = this.props.products;
    if(!products){
      products = await ListCoverageSummaryProducts(id);
    }
    let companies = this.props.companies;
    if(!companies){
      companies  = await ListCoverageSummaryCompanies(id);
    }
    let packageProducts = products.filter((product) => pkg.attributes.products && pkg.attributes.products.indexOf(product.id) != -1);
    let packageCompanies = [];
    if(packageProducts){
      for (let i = 0; i < packageProducts.length; i++) {
        if(packageCompanies){
          if(packageProducts[i].attributes.companies){
            for (let x = 0; x < packageProducts[i].attributes.companies.length; x++) {
              if(packageCompanies.indexOf(packageProducts[i].attributes.companies[x].id) == -1){
                packageCompanies.push(packageProducts[i].attributes.companies[x])
              }
            }
          }
        }
      }
    }

    packageCompanies = companies.filter((company) => packageCompanies.indexOf(company.id) != -1);

    packageProducts = packageProducts.sort((a, b) =>{
        let aName = a.attributes.name
        let bName = b.attributes.name

        if(aName < bName) { return -1; }
        if(aName > bName) { return 1; }
        return 0;
    })

    packageCompanies = packageCompanies.sort((a, b) =>{
        let aName = a.attributes.symbol;
        let bName = b.attributes.symbol;
        if(!aName){
          aName = a.attributes.name
        }
        if(!bName){
          bName = b.attributes.name
        }
        if(a.attributes.name)
        if(aName < bName) { return -1; }
        if(aName > bName) { return 1; }
        return 0;
    })
    this.setState({
      pkg:pkg,
      packageProducts:packageProducts,
      packageCompanies:packageCompanies,
      companies:companies
    })
  }
  render() {
    let item = this.state.pkg;
    let color = "#fff";
    if(item && item.attributes){
      color = item.attributes.color
    }
    if(this.props.layout != 'horizontal'){
      return(
        <div className="full-width">
          <div className="shadow">
            <div className="small-padding white-background left-text"  style={{backgroundColor:color}}>
              {item && item.attributes && item.attributes.name &&
                <h4 className="white" style={{marginTop:0, marginBottom:0}}>{item.attributes.name}</h4>
              }
            </div>
            <div className="small-padding white-background left-text" style={{paddingTop:'12px'}}>
              {item && item.attributes && item.attributes.description &&
                <p className="subheading">{item.attributes.description}</p>
              }
              <div className="row">
                {this.state.packageProducts && this.state.packageProducts.map((data) =>{
                  let targetCompanies = this.state.companies && this.state.companies.filter((company) => data.attributes.companies && data.attributes.companies.indexOf(company.id) != -1);
                  return(
                    <div className="small-12 medium-4 large-3">
                      <div className="row">
                        {(targetCompanies && targetCompanies.length == 1) ?
                            <h6 style={{cursor:'pointer', marginBottom:2, paddingRight:4}} onClick={()=> this.props.onProductClick && this.props.onProductClick(data)}>
                              {!data.attributes.hideTickerFromCoverageSummary && targetCompanies[0].attributes.symbol && <span>{targetCompanies[0].attributes.symbol}</span>}
                              {(!data.attributes.hideTickerFromCoverageSummary && targetCompanies[0].attributes.symbol) ?
                                <span className="subheading"></span>
                                :
                                <span>{data.attributes.name}</span>
                              }
                            </h6>
                            :
                            <h6 style={{cursor:'pointer', marginBottom:2, paddingRight:4}} onClick={()=> this.props.onProductClick && this.props.onProductClick(data)}>
                              <span>{data.attributes.name}</span>
                            </h6>
                        }
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      )
    } else{
      return(
        <div className="full-width">
          <div className="row shadow">
            <div className="small-1 medium-2" style={{backgroundColor:color}}>
              <div style={{height:'100%'}}></div>
            </div>
            <div className="small-11 medium-10 small-padding white-background left-text">
              {item && item.attributes && item.attributes.name &&
                <h4 style={{marginTop:0}}>{item.attributes.name}</h4>
              }
              {item && item.attributes && item.attributes.description &&
                <p className="subheading">{item.attributes.description}</p>
              }
              <div className="row">
                <div className="small-12 medium-6">
                  {this.state.packageProducts && this.state.packageProducts[0] &&
                    <span>
                      <h5><b>{'Products'}</b></h5>
                      {this.state.packageProducts && this.state.packageProducts.map((data) =>{
                        return(
                          <div className="row">
                            <p style={{cursor:'pointer', marginBottom:10}} onClick={()=> this.props.onProductClick && this.props.onProductClick(data)} className="subheading">
                              {data.attributes.symbol ?
                                <span><b>{data.attributes.symbol}</b></span>
                                :
                                <span><b>{data.attributes.name}</b></span>
                              }
                            </p>
                          </div>
                        )
                      })}
                    </span>
                  }
                </div>
                <div className="small-12 medium-6">
                  {this.state.packageCompanies && this.state.packageCompanies[0] &&
                    <span>
                      <h5><b>{'Companies'}</b></h5>
                      {this.state.packageCompanies && this.state.packageCompanies.map((data) =>{
                        return(<p style={{cursor:'pointer', marginBottom:10}} onClick={()=> this.props.onBundleClick && this.props.onBundleClick(data)}  className="subheading">{data.attributes.name}</p>)
                      })}
                    </span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default withRouter(PackageModal)
